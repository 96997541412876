import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import currencyList from '../../helpers/currencyList.json'
import isIterable from "../../helpers/isIterable"
import arrayObjectIndexOf from '../../helpers/indexOfObject.js'
import availableQuickActions from '../../helpers/availableQuickActions'

import Calendar from '../Calendar'
import QuickActionPopupHeader from './QuickActionPopupHeader'
import DocsSearchModal from './DocsSearchModal'
import SelectedDocsSummaryRowWithAccounts from './SelectedDocsSummaryRowWithAccounts'
import CostsTableVirtualized from './CostsTableVirtualized'
import CostsTable from './CostsTable'
import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'
import AnimatedCheckmark from '../AnimatedCheckmark'

import { ReportsActions } from '../../actions/ReportsActions'
import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { AlertActions } from '../../actions/AlertActions'
import { ExcelSessionActions } from '../../actions/ExcelSessionActions'

import '../../css/QuickActions.css'
import '../../css/Report.css'
import { Spinner } from 'react-activity'

export class ExportBankTransfers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableHeight: (window.innerHeight * 0.95) - 400,
            showVirtualizedTable: false,
            allDocsSelected: false,
            docsSelectedForReport: [],
            roleInSelectedProject: '',
            selectedDocsSums: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            previewFile: {},
            availableAccounts: [],
            selectedAccounts: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, { id: 0 }])
                })
            ),
            paymentDate: 'TODAY',
            dueDateMinus: 3,
            customPaymentsDate: moment().add(2, 'day').format('YYYY-MM-DD'),
            showCustomDatePicker: false,
            createZip: false,
            createExcel: false,
            markPaid: false,

            highlightTable: false,
            highlightAccounts: false,
            highlightOptions: false,

            searchAllUnpaid: true,
            dateFilter: 'importDate',
            period: ''
        }
        this.tableRef = React.createRef()
        this.customDateRef = React.createRef()
    }

    componentDidMount() {
        const { currentCompany, currentProject, user } = this.props
        if (currentCompany && currentCompany.company) {
            this.setState({
                availableAccounts: currentCompany.company.banks
            })
            this.setDefaultSelectedAccounts(currentCompany.company.banks)
        }

        if (user.new_easydocs_version && currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allUnpaid: true })
        }

        var lastSelectedOptions = localStorage.getItem('quickActionBankTransfersChoices')
        if (lastSelectedOptions) {
            lastSelectedOptions = JSON.parse(lastSelectedOptions)
            this.setState({
                paymentDate: lastSelectedOptions.paymentDate || 'TODAY',
                createZip: lastSelectedOptions.createZip || false,
                createExcel: lastSelectedOptions.createExcel || false,
                markPaid: lastSelectedOptions.markPaid || false,
            })
        }
        window.addEventListener("keydown", this.keyDownHandler, false)
    }

    componentDidUpdate(prevProps) {
        const { currentCompany, currentProject, user, docsDownloadedForRaport, sendGetDocumentsRequest } = this.props
        if (!prevProps.currentCompany && currentCompany && currentCompany.company) {
            this.setState({
                availableAccounts: currentCompany.company.banks
            })
        } else if (prevProps.currentCompany && currentCompany && prevProps.currentCompany.id !== currentCompany.id && currentCompany.company) {
            this.setState({
                availableAccounts: currentCompany.company.banks
            })
            this.setDefaultSelectedAccounts(currentCompany.company.banks)
        }

        if (!prevProps.sendGetDocumentsRequest && sendGetDocumentsRequest) {
            this.clearStateOnDocsSearch()
        }

        if (prevProps.docsDownloadedForRaport && prevProps.docsDownloadedForRaport.length === 0 && docsDownloadedForRaport && docsDownloadedForRaport.length > 0) {
            this.setState({
                docsSelectedForReport: [],
                previewFile: {}
            }, () => {
                this.selectAll()
                this.setTableHeight()

                if (currentCompany && currentCompany.company) {
                    this.setDefaultSelectedAccounts(currentCompany.company.banks)
                }
            })
        }

        if (user.new_easydocs_version && currentProject?.id && currentProject?.id !== prevProps.currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allUnpaid: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownHandler, false)
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {
            //ESC
            // const { previewFile } = this.state
            // if (!previewFile || previewFile === {}) {
            //     this.props.close()
            // }
            //  TODO: needs to take filters modal into account
        } else if (e.keyCode === 38) {
            //UP ARROW
            this.goToPrevProject()
        } else if (e.keyCode === 40) {
            //DOWN ARROW
            this.goToNextProject()
        }
    }

    handleClick = (e) => {
        const { showCustomDatePicker } = this.state
        if (showCustomDatePicker && this.customDateRef && this.customDateRef.current && !this.customDateRef.current.contains(e.target)) {
            this.setState({
                showCustomDatePicker: false,
            })
        }
    }

    goToPrevProject = () => {
        const { userProjects, currentProject } = this.props
        var projectIndex = arrayObjectIndexOf(userProjects, currentProject.id, 'id')

        console.log(projectIndex)
        if (projectIndex > 0) {
            var prevProject = userProjects[projectIndex - 1]
            while (projectIndex > 0 && !prevProject.payments) {
                projectIndex -= 1
                prevProject = userProjects[projectIndex]
            }

            if (prevProject.payments) {
                this.props.switchDashboardViewModel(prevProject.id)
                this.loadNewDocsAfterProjectSwitch(prevProject.id)

                const { userCompanies } = this.props
                var newCompanyIndex = arrayObjectIndexOf(userCompanies, prevProject.company.id, 'company_id')
                if (newCompanyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                }
            }

        }
    }

    goToNextProject = () => {
        const { userProjects, currentProject } = this.props
        var projectIndex = arrayObjectIndexOf(userProjects, currentProject.id, 'id')

        console.log(projectIndex)
        if (projectIndex < userProjects.length - 1) {
            var prevProject = userProjects[projectIndex + 1]
            while (projectIndex < userProjects.length - 1 && !prevProject.payments) {
                projectIndex += 1
                prevProject = userProjects[projectIndex]
            }

            if (prevProject.payments) {
                this.props.switchDashboardViewModel(prevProject.id)
                this.loadNewDocsAfterProjectSwitch(prevProject.id)

                const { userCompanies } = this.props
                var newCompanyIndex = arrayObjectIndexOf(userCompanies, prevProject.company.id, 'company_id')
                if (newCompanyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                }
            }

        }
    }

    clearStateOnDocsSearch = () => {
        this.setState({
            docsSelectedForReport: [],
            selectedDocsSums: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            selectedAccounts: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, { id: 0 }])
                })
            ),
            previewFile: {}
        })
    }

    setTableHeight = () => {
        const { docsDownloadedForRaport } = this.props
        var heightToFitAllDocs = docsDownloadedForRaport.length * 40 + 40

        if (heightToFitAllDocs < (window.innerHeight * 0.95) - 440) {
            this.setState({
                tableHeight: heightToFitAllDocs,
                showVirtualizedTable: false
            })
        } else {
            this.setState({
                tableHeight: (window.innerHeight * 0.95) - 440,
                showVirtualizedTable: true
            })
        }
    }

    setDefaultSelectedAccounts = accounts => {
        var currentSelectedAccounts = Object.fromEntries(
            currencyList.map(c => {
                return ([c, { id: 0 }])
            })
        )

        accounts.forEach(bankAccount => {
            if (bankAccount && bankAccount.currency && currentSelectedAccounts[bankAccount.currency] && currentSelectedAccounts[bankAccount.currency].id === 0) {
                currentSelectedAccounts[bankAccount.currency] = bankAccount
            }
        })

        this.setState({
            selectedAccounts: currentSelectedAccounts
        })
    }

    selectAccount = (currency, bankAccount) => {
        var currentSelectedAccounts = { ...this.state.selectedAccounts }

        currentSelectedAccounts[currency] = bankAccount

        this.setState({
            selectedAccounts: currentSelectedAccounts
        })
    }

    selectAll = () => {
        const { docsDownloadedForRaport } = this.props
        var currentSelectedDocs = [...this.state.docsSelectedForReport]
        var { selectedDocsSums } = this.state
        var oneSelected = false

        docsDownloadedForRaport.forEach(doc => {
            if (!currentSelectedDocs.find(d => d.id === doc.id) && !doc.alreadyInBankTransferFile) {
                currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency })
                oneSelected = true

                selectedDocsSums[doc.currency] += parseFloat(doc.price)
            }
        })
        this.setState({
            docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
            selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            allDocsSelected: oneSelected
        })
    }

    addDocToSelected = (e, doc) => {
        e.preventDefault()
        var currentSelected = this.state.docsSelectedForReport

        if (!doc.alreadyInBankTransferFile) {
            if (currentSelected.find(e => e.id === doc.id)) {
                var { selectedDocsSums } = this.state
                selectedDocsSums[doc.currency] -= parseFloat(doc.price)

                this.setState({
                    docsSelectedForReport: currentSelected.filter(e => {
                        if (e.id === doc.id && e.type === doc.type) return false
                        return true
                    }),
                    selectedDocsSums: selectedDocsSums,
                    allDocsSelected: false
                })
            } else {
                currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, price: doc.price })
                var { selectedDocsSums } = this.state
                selectedDocsSums[doc.currency] += parseFloat(doc.price)

                this.setState({
                    docsSelectedForReport: currentSelected,
                    selectedDocsSums: selectedDocsSums,
                })
            }
        }
    }

    downloadReport = (e, projectId, reportName) => {
        e.preventDefault()

        this.props.downloadReport(projectId, reportName)
    }

    togglePaymentForDoc = (e, doc) => {
        e.preventDefault()
        const { user } = this.props
        if (!doc.alreadyPaid) {
            if (doc.type === 'PAYMENT_ORDER') {
                this.props.tooglePaymentForTransfer(doc.id, doc.projectId, user.id, true, 'transfer')
            } else {
                this.props.tooglePaymentForDoc(doc.id, doc.fileId, doc.projectId, 'transfer')
            }
        } else {
            if (doc.alreadyInBankTransferFile && doc.bankTransferFileName) {
                this.props.alertWarn("This document is included in bank transfer file. To prevent mistakes you can't unmark it", 8000)
            } else if (doc.alreadyInReport && doc.reportName) {
                this.props.alertWarn("This document is included in report file. To prevent mistakes you can't unmark it", 8000)
            } else {
                if (doc.type === 'PAYMENT_ORDER') {
                    this.props.tooglePaymentForTransfer(doc.id, doc.projectId, user.id, false, '')
                } else {
                    this.props.tooglePaymentForDoc(doc.id, doc.fileId, doc.projectId, null)
                }
            }

        }
    }

    toggleAccountedStatus = (id, fileId, projectId, type, accounted) => {
        if (type === 'PAYMENT_ORDER') {
            this.props.toogleAccountmentStatusForTransfer(id, projectId, 0, accounted);
        } else {
            this.props.toogleAccountmentStatusForDoc(id, fileId, projectId, accounted);
        }
    }

    fileIconClick = (e, projectId, file, id, docType, fileId, alreadyPaid) => {
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: file,
                id: id,
                docType: docType
            }
        })
        if (docType === 'PAYMENT_ORDER') {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === id)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                } else {
                    this.props.alertWarn(this.context.t('Payment order not found'))
                }
            } else {
                this.props.alertWarn(this.context.t('Payment order not found'))
            }
        } else {
            this.props.getFileBody(parseInt(projectId), file, fileId, alreadyPaid ? 'DONE' : 'IN_PROGRESS', alreadyPaid ? 4 : 3)
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {}
        })
    }

    checkIfDocMatchesFilterOptions = () => {
        return true
    }

    setPaymentDate = newDate => {
        console.log('setPaymentDate')
        console.log(newDate)
        this.setState({
            paymentDate: newDate
        })

        var lastSelectedOptions = localStorage.getItem('quickActionBankTransfersChoices')
        if (lastSelectedOptions) {
            lastSelectedOptions = JSON.parse(lastSelectedOptions)
            lastSelectedOptions.paymentDate = newDate

            localStorage.setItem('quickActionBankTransfersChoices', JSON.stringify(lastSelectedOptions))
        }
    }

    toggleCreateZip = () => {
        const { createZip } = this.state
        this.setState({
            createZip: !createZip
        })

        var lastSelectedOptions = localStorage.getItem('quickActionBankTransfersChoices')
        if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
        else lastSelectedOptions = {}

        lastSelectedOptions.createZip = !createZip
        localStorage.setItem('quickActionBankTransfersChoices', JSON.stringify(lastSelectedOptions))
    }

    toggleCreateExcel = () => {
        const { createExcel } = this.state
        this.setState({
            createExcel: !createExcel
        })

        var lastSelectedOptions = localStorage.getItem('quickActionBankTransfersChoices')
        if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
        else lastSelectedOptions = {}

        lastSelectedOptions.createExcel = !createExcel
        localStorage.setItem('quickActionBankTransfersChoices', JSON.stringify(lastSelectedOptions))

    }

    toggleMarkPaid = () => {
        const { markPaid } = this.state
        this.setState({
            markPaid: !markPaid
        })

        var lastSelectedOptions = localStorage.getItem('quickActionBankTransfersChoices')
        if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
        else lastSelectedOptions = {}

        lastSelectedOptions.markPaid = !markPaid
        localStorage.setItem('quickActionBankTransfersChoices', JSON.stringify(lastSelectedOptions))
    }

    execute = () => {
        const { docsSelectedForReport, selectedAccounts, createZip, createExcel, paymentDate, dueDateMinus } = this.state
        const { currentProject } = this.props
        var valid = true
        var docsSplitByCurrency = Object.fromEntries(
            currencyList.map(c => {
                return ([c, { docs: [], bankId: 0 }])
            })
        )

        if (docsSelectedForReport.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('You must select at least one document'))
        } else {
            docsSelectedForReport.map(d => {
                if (docsSplitByCurrency[d.currency]) {
                    docsSplitByCurrency[d.currency].docs.push(d)
                }
            })

            for (const currency in selectedAccounts) {
                console.log(currency)
                if (selectedAccounts.hasOwnProperty(currency) && docsSplitByCurrency.hasOwnProperty(currency)) {

                    console.log(selectedAccounts[currency])
                    docsSplitByCurrency[currency].bankId = selectedAccounts[currency].id
                    console.log(docsSplitByCurrency[currency])

                    if (docsSplitByCurrency[currency].docs.length && !docsSplitByCurrency[currency].bankId) {
                        valid = false
                        this.props.alertWarn(this.context.t('You must select a bank account for documents in the currency: {n}', { n: currency }))
                    }
                }
            }
        }

        if (paymentDate === 'DUE_DATE_MINUS' && !dueDateMinus) {
            this.props.alertWarn("The transfer date is incorrect")
        }

        if (valid) {
            var transferCurrenciesToGenerate = []
            for (const currency in selectedAccounts) {
                if (docsSplitByCurrency.hasOwnProperty(currency)) {
                    if (docsSplitByCurrency[currency].docs.length && docsSplitByCurrency[currency].bankId) {
                        transferCurrenciesToGenerate.push(currency)
                    }
                }
            }

            this.setState({
                transferCurrenciesToGenerate: transferCurrenciesToGenerate,
                showActionsLoadingWindow: true
            })

            this.generateTransfers(transferCurrenciesToGenerate, docsSplitByCurrency, () => {
                if (createZip) {
                    var filesToDownload = {
                        ids: []
                    }
                    var paymentOrdersToDownload = {
                        ids: []
                    }
                    docsSelectedForReport.map(doc => {
                        if (doc.type !== 'PAYMENT_ORDER') {
                            filesToDownload.ids.push(doc.fileId)
                        } else {
                            paymentOrdersToDownload.ids.push(doc.fileId)
                        }
                    })
                    this.props.downloadMultipleFiles(currentProject.id, currentProject.code, filesToDownload, paymentOrdersToDownload, false, () => {
                        this.setState({
                            finishedZipGeneration: true
                        })
                        if (createExcel) {
                            this.props.generateReport(docsSelectedForReport, [], 'Costs', true, null, currentProject.id, docsSelectedForReport[0].currency, () => {
                                this.setState({
                                    finishedExcelGeneration: true,
                                    finishedAllActions: true,
                                    docsSelectedForReport: [],
                                    selectedDocsSums: Object.fromEntries(
                                        currencyList.map(c => {
                                            return ([c, 0])
                                        })
                                    ),
                                    previewFile: {},
                                    selectedAccounts: Object.fromEntries(
                                        currencyList.map(c => {
                                            return ([c, { id: 0 }])
                                        })
                                    ),
                                })
                            })
                        } else {
                            this.setState({
                                finishedAllActions: true,
                                docsSelectedForReport: [],
                                selectedDocsSums: Object.fromEntries(
                                    currencyList.map(c => {
                                        return ([c, 0])
                                    })
                                ),
                                previewFile: {},
                                selectedAccounts: Object.fromEntries(
                                    currencyList.map(c => {
                                        return ([c, { id: 0 }])
                                    })
                                ),
                            })
                        }
                    })
                } else if (createExcel) {
                    this.props.generateReport(docsSelectedForReport, [], 'Costs', true, null, currentProject.id, docsSelectedForReport[0].currency, () => {
                        this.setState({
                            finishedExcelGeneration: true,
                            finishedAllActions: true,
                            docsSelectedForReport: [],
                            selectedDocsSums: Object.fromEntries(
                                currencyList.map(c => {
                                    return ([c, 0])
                                })
                            ),
                            previewFile: {},
                            selectedAccounts: Object.fromEntries(
                                currencyList.map(c => {
                                    return ([c, { id: 0 }])
                                })
                            ),
                        })
                    })
                } else {
                    this.setState({
                        finishedAllActions: true,
                        docsSelectedForReport: [],
                        selectedDocsSums: Object.fromEntries(
                            currencyList.map(c => {
                                return ([c, 0])
                            })
                        ),
                        previewFile: {},
                        selectedAccounts: Object.fromEntries(
                            currencyList.map(c => {
                                return ([c, { id: 0 }])
                            })
                        ),
                    })
                }
            })
        }
    }

    generateTransfers = async (transferCurrenciesToGenerate, docsSplitByCurrency, callback) => {
        const { paymentDate, dueDateMinus, customPaymentsDate, markPaid } = this.state
        const { currentProject } = this.props
        var additionalPaymentDateOptions = {}
        if (paymentDate === 'DUE_DATE_MINUS') {
            additionalPaymentDateOptions.dueDateMinus = dueDateMinus
        } else if (paymentDate === 'CUSTOM_DATE') {
            additionalPaymentDateOptions.customPaymentsDate = customPaymentsDate
        }

        var transfersCount = transferCurrenciesToGenerate.length
        for (var i = 0; i < transfersCount; i++) {
            var currency = transferCurrenciesToGenerate[i]
            await new Promise((resolve, reject) => {
                if (currency === 'PLN') {
                    this.props.generateBankTransfersFile(docsSplitByCurrency[currency].docs, 'Costs', currentProject.id, paymentDate, docsSplitByCurrency[currency].bankId, markPaid, additionalPaymentDateOptions, () => {
                        resolve()
                        this.setState({
                            [`finishedGenerating${currency}`]: true
                        })
                    })
                } else {
                    this.props.generateBankTransfetFileForInternationalTransfers(docsSplitByCurrency[currency].docs, 'Costs', currentProject.id, paymentDate, docsSplitByCurrency[currency].bankId, markPaid, additionalPaymentDateOptions, () => {
                        resolve()
                        this.setState({
                            [`finishedGenerating${currency}`]: true
                        })
                    })
                }
            })
        }

        callback()

        // const promises = transferCurrenciesToGenerate.map(async (currency) => {
        //     return new Promise((resolve, reject) => {
        //         if (currency === 'PLN') {
        //             this.props.generateBankTransfersFile(docsSplitByCurrency[currency].docs, 'Costs', currentProject.id, paymentDate, docsSplitByCurrency[currency].bankId, markPaid, additionalPaymentDateOptions, () => {
        //                 resolve()
        //                 this.setState({
        //                     [`finishedGenerating${currency}`]: true
        //                 })
        //             })
        //         } else {
        //             this.props.generateBankTransfetFileForInternationalTransfers(docsSplitByCurrency[currency].docs, 'Costs', currentProject.id, paymentDate, docsSplitByCurrency[currency].bankId, markPaid, additionalPaymentDateOptions, () => {
        //                 resolve()
        //                 this.setState({
        //                     [`finishedGenerating${currency}`]: true
        //                 })
        //             })
        //         }
        //     })
        // })

        // const generatedTransfers = await Promise.all(promises)
        // callback()
    }

    closeAction = () => {
        const { finishedAllActions } = this.state

        if (finishedAllActions) {
            this.setState({
                showActionsLoadingWindow: false,
                finishedAllActions: false,
            })
        }
    }

    handleMouseEnterStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: true
                })
                break
            case 2:
                this.setState({
                    highlightAccounts: true
                })
                break
            case 3:
                this.setState({
                    highlightOptions: true
                })
                break
        }
    }

    handleMouseLeaveStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: false
                })
                break
            case 2:
                this.setState({
                    highlightAccounts: false
                })
                break
            case 3:
                this.setState({
                    highlightOptions: false
                })
                break
        }
    }

    getFiltersFromSearchForm = (data) => {
        this.setState({
            highlightTable: false,
            searchAllUnpaid: data.searchAllUnpaid,
            dateFilterText: data.dateFilterText,
            period: data.periodText,
            dateFilter: data.dateFilter,
            startDate: data.startDate,
            endDate: data.endDate
        })
    }

    loadNewDocsAfterProjectSwitch = projectId => {
        const { startDate, endDate, dateFilter, searchAllUnpaid } = this.state
        var additionalFilteringCriteria = {
            allUnpaid: searchAllUnpaid,
            unpaid: !searchAllUnpaid
        }
        this.props.getDocuments(startDate, endDate, projectId, 'Costs', dateFilter, null, additionalFilteringCriteria)
    }

    toggleExcelSession = () => {
        const { activeExcelSession, excelSessionDocs, generatingSessionExcel } = this.props
        if (activeExcelSession) {
            if (!generatingSessionExcel) {
                if (excelSessionDocs.length) {
                    this.props.generateSessionExcel(excelSessionDocs)
                } else {
                    this.props.stopExcelSession(excelSessionDocs)
                }
            }
        } else {
            this.props.startExcelSession()
        }
    }

    changeDueDateMinus = e => {
        e.preventDefault()
        var { value } = e.target
        value = value.replace(/[^0-9]/gm, '')
        this.setState({ dueDateMinus: value })
    }

    tooglePaymentsCustomDateCalendar = () => {
        const { showCustomDatePicker } = this.state
        this.setState({
            showCustomDatePicker: !showCustomDatePicker
        })
    }

    selectCustomPaymentsDate = date => {
        if (date) {
            if (date.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                this.props.alertWarn("Selected date can't be in the past")
            } else {
                this.setState({
                    customPaymentsDate: date.format('YYYY-MM-DD'),
                    showCustomDatePicker: false,
                })
            }
        }
    }

    render() {
        const {
            highlightTable, highlightAccounts, highlightOptions,
            searchAllUnpaid, dateFilterText, period,
            tableHeight, showVirtualizedTable, allDocsSelected, docsSelectedForReport, previewFile,
            selectedDocsSums, roleInSelectedProject, availableAccounts, selectedAccounts,
            paymentDate, dueDateMinus, customPaymentsDate, showCustomDatePicker, createZip, createExcel, markPaid,
            showActionsLoadingWindow, transferCurrenciesToGenerate, finishedAllActions,
        } = this.state
        const {
            docsDownloadedForRaport, sendGetDocumentsRequest,
            user, tabIsActive, activeExcelSession, generatingSessionExcel
        } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`quic-actions-popup reports ${showActionsLoadingWindow ? 'waiting-for-actions' : ''} ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`} onClick={e => this.handleClick(e)}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsDownloadedForRaport}
                                location={{
                                    pathname: '/actions/preview/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={this.checkIfDocMatchesFilterOptions}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        showActionsLoadingWindow ? (
                            <div className="actions-loading-window">
                                <div className="actions">
                                    <h4>
                                        {finishedAllActions ? this.context.t('Ready!') : this.context.t('Just a moment...')}
                                    </h4>

                                    {
                                        transferCurrenciesToGenerate.map(currency => {
                                            return (
                                                <div className="action">
                                                    {
                                                        this.state[`finishedGenerating${currency}`] ? (
                                                            <AnimatedCheckmark />
                                                        ) : (
                                                            <div className="loading-action">
                                                                <Spinner color={'#69B792'} size={14} speed={0.8} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="action-text">
                                                        {this.context.t('Generating a batch of {n} transfers', { n: currency })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    {
                                        createZip ? (
                                            <div className="action">
                                                {
                                                    this.state.finishedZipGeneration ? (
                                                        <AnimatedCheckmark />
                                                    ) : (
                                                        <div className="loading-action">
                                                            <Spinner color={'#69B792'} size={14} speed={0.8} />
                                                        </div>
                                                    )
                                                }

                                                <div className="action-text">
                                                    {this.context.t('Generating zip package')}
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    {
                                        createExcel ? (
                                            <div className="action">
                                                {
                                                    this.state.finishedExcelGeneration ? (
                                                        <AnimatedCheckmark />
                                                    ) : (
                                                        <div className="loading-action">
                                                            <Spinner color={'#69B792'} size={14} speed={0.8} />
                                                        </div>
                                                    )
                                                }

                                                <div className="action-text">
                                                    {this.context.t('Generating Excel payment summary')}
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className={`close-button ${finishedAllActions ? 'active' : ''}`} onClick={() => this.closeAction()}>{this.context.t('Close')}</div>
                                </div>
                            </div>
                        ) : null
                    }

                    <div className={`content`}>
                        <QuickActionPopupHeader
                            title={"Payment assistant"}
                            type={"paymentAssistane"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.bankTransfers, false)}
                            getDocs={this.loadNewDocsAfterProjectSwitch}
                        />

                        <div className="action-instructions">
                            <div className="steps-wrapper">
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(1)} onMouseLeave={() => this.handleMouseLeaveStep(1)}>
                                    <div className="number">1</div>
                                    <div className="description">{this.context.t('Select the documents you want to pay for')}</div>
                                    <DocsSearchModal
                                        clearParentStateOnDocsSearch={this.clearStateOnDocsSearch}
                                        selectedType={"Costs"}
                                        searchForUnpaid={true}
                                        setFilters={this.getFiltersFromSearchForm}
                                        closeActionOnEsc={() => this.props.toggleQuickAction(availableQuickActions.bankTransfers, false)}
                                    />
                                </div>
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(2)} onMouseLeave={() => this.handleMouseLeaveStep(2)}>
                                    <div className="number">2</div>
                                    <div className="description">{this.context.t('Select the accounts from which payments are to be made')}</div>
                                </div>
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(3)} onMouseLeave={() => this.handleMouseLeaveStep(3)}>
                                    <div className="number">3</div>
                                    <div className="description">{this.context.t('Select additional options')}</div>
                                </div>
                            </div>

                            {
                                user.id === 2 || user.id === 62 || user.id === 597 ? (
                                    <div className={`excel-session ${generatingSessionExcel ? 'generating-excel' : ''} ${activeExcelSession ? 'stop-session' : 'start-session'}`} onClick={() => this.toggleExcelSession()}>
                                        {
                                            generatingSessionExcel ? (
                                                <div className="activity-indicator">
                                                    <Spinner color={'#69B792'} size={13} speed={0.8} />
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className={`hightlight-container ${highlightTable ? 'highlight' : ''}`}>
                            <span className="number">1</span>
                            {
                                sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 ? (
                                    <div className="docs-loading-for-report">
                                        <div className="image"></div>
                                        <div className="text">
                                            <div className="header">
                                                {this.context.t('Loading')}
                                            </div>
                                            <div className="instruction">
                                                {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    docsDownloadedForRaport.length > 0 ? (
                                        showVirtualizedTable ? (
                                            <CostsTableVirtualized
                                                tableHeight={tableHeight}
                                                setTableRef={this.tableRef}
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        ) : (
                                            <CostsTable
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        )
                                    ) : (
                                        <div className="no-docs-found">
                                            <div className="image"></div>
                                            <div className="text">
                                                <div className="header">
                                                    {
                                                        searchAllUnpaid ? (
                                                            this.context.t('No unpaid documents')
                                                        ) : (
                                                            this.context.t('No unpaid documents in the {d} period {p}', { d: this.context.t(dateFilterText), p: period })
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>

                        {
                            docsDownloadedForRaport.length > 0 ? (
                                <div className="footer-container">
                                    <div className={`hightlight-container accounts ${highlightAccounts ? 'highlight' : ''}`}>
                                        <span className="number">2</span>
                                        <SelectedDocsSummaryRowWithAccounts
                                            selectedDocsCount={docsSelectedForReport.length}
                                            selectedDocsSums={selectedDocsSums}
                                            availableAccounts={availableAccounts}
                                            selectedAccounts={selectedAccounts}
                                            selectAccount={this.selectAccount}
                                        />

                                        {
                                            docsSelectedForReport.length === 0 ? (
                                                <div className="no-selected-documents">
                                                    {this.context.t('You must select at least one document')}
                                                </div>
                                            ) : null
                                        }
                                    </div>


                                    <div className={`hightlight-container options ${highlightOptions ? 'highlight' : ''}`}>
                                        <span className="number">3</span>
                                        <div className="options-to-choose">
                                            <div className="payment-date-options">
                                                <div className="label">{this.context.t('Set the date of transfers to:')}</div>
                                                <div className="options">
                                                    <div className={`option ${paymentDate === 'TODAY' ? 'selected' : ''}`} onClick={() => this.setPaymentDate('TODAY')}>{this.context.t('today')}</div>
                                                    <div className={`option ${paymentDate === 'TOMORROW' ? 'selected' : ''}`} onClick={() => this.setPaymentDate('TOMORROW')}>{this.context.t('tomorrow')}</div>
                                                    <div className={`option ${paymentDate === 'DUE_DATE' ? 'selected' : ''}`} onClick={() => this.setPaymentDate('DUE_DATE')}>{this.context.t('document\'s due dates')}</div>
                                                    <div className="break"></div>
                                                    <div className={`option due-date-minus ${paymentDate === 'DUE_DATE_MINUS' ? 'selected' : ''}`} onClick={() => this.setPaymentDate('DUE_DATE_MINUS')}>
                                                        <div className="form-group">
                                                            <input type="text" name="dueDateMinus" id="dueDateMinus" value={dueDateMinus} onChange={e => this.changeDueDateMinus(e)} />
                                                        </div>
                                                        {this.context.t('days before document\'s due dates')}
                                                    </div>
                                                    <div className={`option custom-due-date ${paymentDate === 'CUSTOM_DATE' ? 'selected' : ''}`} onClick={() => this.setPaymentDate('CUSTOM_DATE')} ref={this.customDateRef}>
                                                        <div className="form-group">
                                                            <input type="text" className={`${showCustomDatePicker ? 'calendar-opene' : ''}`} name="customPaymentsDate" id="customPaymentsDate" value={customPaymentsDate} onClick={() => this.tooglePaymentsCustomDateCalendar()} />
                                                            <label htmlFor="customPaymentsDate" onClick={() => this.tooglePaymentsCustomDateCalendar()}>{this.context.t('Transfers date')}</label>
                                                            <span className="calendar-icon" onClick={() => this.tooglePaymentsCustomDateCalendar()}></span>

                                                            {
                                                                showCustomDatePicker ? (
                                                                    <Calendar
                                                                        selectDay={this.selectCustomPaymentsDate}
                                                                        unselectDay={this.selectCustomPaymentsDate}
                                                                        selectedDay={
                                                                            moment(customPaymentsDate, 'YYYY-MM-DD').isValid() ? {
                                                                                display: moment(customPaymentsDate, 'YYYY-MM-DD').format('LL'),
                                                                                value: moment(customPaymentsDate, 'YYYY-MM-DD')
                                                                            } : {
                                                                                display: null,
                                                                                value: null
                                                                            }
                                                                        }
                                                                        projectId={'none'}
                                                                        startDate={moment(customPaymentsDate, 'YYYY-MM-DD').isValid() ? moment(customPaymentsDate, 'YYYY-MM-DD') : moment()}
                                                                    />
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="additional-actions">
                                                <div className="additional-action">
                                                    <div className={`checkbox ${createZip ? 'checked' : ''}`} onClick={() => this.toggleCreateZip()}></div>
                                                    <span className="label" onClick={() => this.toggleCreateZip()}>{this.context.t('create a zip package with documents')}</span>
                                                </div>
                                                <div className="additional-action">
                                                    <div className={`checkbox ${createExcel ? 'checked' : ''}`} onClick={() => this.toggleCreateExcel()}></div>
                                                    <span className="label" onClick={() => this.toggleCreateExcel()}>{this.context.t('create a payment summary in Excel')}</span>
                                                </div>
                                                <div className="additional-action">
                                                    <div className={`checkbox ${markPaid ? 'checked' : ''}`} onClick={() => this.toggleMarkPaid()}></div>
                                                    <span className="label" onClick={() => this.toggleMarkPaid()}>{this.context.t('mark documents as "paid"')}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="confirm-button" onClick={() => this.execute()}>{this.context.t("Execute")}</div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

ExportBankTransfers.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    groupedProjects: state.User.groupedProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    docsDownloadedForRaport: isIterable(state.Reports.docsDownloadedForRaport) ? [...state.Reports.docsDownloadedForRaport] : [],
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],

    activeExcelSession: state.ExcelSession.sessionActive,
    excelSessionDocs: state.ExcelSession.sessionDocs,
    generatingSessionExcel: state.ExcelSession.generatingExcel,
})

const mapDispatchToProps = {
    getDocuments: ReportsActions.getDocuments,
    downloadReport: ReportsActions.downloadReport,
    tooglePaymentForDoc: ReportsActions.tooglePaymentForDoc,
    tooglePaymentForTransfer: ReportsActions.tooglePaymentForTransfer,
    toogleAccountmentStatusForDoc: ReportsActions.toogleAccountmentStatusForDoc,
    toogleAccountmentStatusForTransfer: ReportsActions.toogleAccountmentStatusForTransfer,
    generateBankTransfersFile: ReportsActions.generateBankTransfersFile,
    generateBankTransfetFileForInternationalTransfers: ReportsActions.generateBankTransfetFileForInternationalTransfers,
    generateReport: ReportsActions.generateReport,
    downloadMultipleFiles: FileActions.downloadMultipleFiles,

    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    getTransfers: TransfersActions.getAllMyTransfers,
    getFiles: FileActions.getFiles,

    toggleQuickAction: UserActions.toggleQuickAction,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    startExcelSession: ExcelSessionActions.startExcelSession,
    stopExcelSession: ExcelSessionActions.stopExcelSession,
    generateSessionExcel: ExcelSessionActions.generateSessionExcel,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportBankTransfers)