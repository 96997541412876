export const QuickActionsConsts = {
    TOGGLE_BANK_TRANSFERS: 'TOGGLE_BANK_TRANSFERS',
    TOGGLE_DOCS_EXPORT: 'TOGGLE_DOCS_EXPORT',
    TOGGLE_EXPENSES_ASSISTANT: 'TOGGLE_EXPENSES_ASSISTANT'
}

const initialState = {
    showExportBankTransfers: false,
    showExportDocs: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case QuickActionsConsts.TOGGLE_BANK_TRANSFERS:
            return { ...state, ...{ showExportBankTransfers: action.show } }
        case QuickActionsConsts.TOGGLE_DOCS_EXPORT:
            return { ...state, ...{ showExportDocs: action.show } }
        case QuickActionsConsts.TOGGLE_EXPENSES_ASSISTANT:
            return { ...state, ...{ showExpensesAssistant: action.show } }
        default:
            return state
    }
}