import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import { FileActions } from '../../actions/FileActions'
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'

import '../../css/OcrPage.css'

import AvailableInOtherPlanLabel from '../AvailableInOtherPlanLabel'

export class OcrPageDocQuestions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showDocumentTypeList: false,
            showPaymentMethodList: false,
            showExpenseUsersList: false,
            projectMembers: [],
            showCurrencyList: false,
            showPaymentTypeList: false,
            showPriority: false
        }

        this.containerRef = React.createRef()
    }

    componentDidMount() {
        this.setState({
            projectMembers: this.props.projectMembers
        })

        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showDocumentTypeList) this.toggleDocumentTypeList()
            else if (this.state.showPaymentMethodList) this.togglePaymentMethodList()
            else if (this.state.showExpenseUsersList) this.toggleExpenseUsersList()
            else if (this.state.showCurrencyList) this.toggleCurrncyList()
            else if (this.state.showPaymentTypeList) this.togglePaymentTypeList()
            else if (this.state.showPriority) this.togglePriorityList()
        }
    }

    handleClick = (event) => {
        if (this.containerRef && !this.containerRef.current.contains(event.target)) {
            this.setState({
                showDocumentTypeList: false,
                showPaymentMethodList: false,
                showExpenseUsersList: false,
                showCurrencyList: false,
                showPaymentTypeList: false,
                showPriority: false
            })
        } else if (this.state.showDocumentTypeList && !this.hasAncestorId(event.target, 'doc-type-question')) {
            this.toggleDocumentTypeList()
        } else if (this.state.showPaymentMethodList && !this.hasAncestorId(event.target, 'payment-method-question')) {
            this.togglePaymentMethodList()
        } else if (this.state.showExpenseUsersList && !this.hasAncestorId(event.target, 'expense-owner-question')) {
            this.toggleExpenseUsersList()
        } else if (this.state.showCurrencyList && !this.hasAncestorId(event.target, 'currency-question')) {
            this.toggleCurrncyList()
        } else if (this.state.showPaymentTypeList && !this.hasAncestorId(event.target, 'payment-type-question')) {
            this.togglePaymentTypeList()
        } else if (this.state.showPriority && !this.hasAncestorId(event.target, 'priority-question')) {
            this.togglePriorityList()
        }
    }

    hasAncestorId = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    toCapitalCase = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    toggleDocumentTypeList = () => {
        this.setState({
            showDocumentTypeList: !this.state.showDocumentTypeList
        })
    }

    getDocTypeLabel = docType => {
        switch (docType) {
            case 'COSTS':
                return this.context.t('Cost')
            case 'EXPENSES_REPORT':
                return this.context.t('Cost')
            case 'MAG':
                return this.context.t('Warehouse doc')
            case 'EXPENSES':
                return this.context.t('Expense')
            case 'AGREEMENT':
                return this.context.t('Agreement')
            case 'INCOME':
                return this.context.t('Income doc')
            case 'CORPORATE':
                return this.context.t('Corporate')
            case 'CORRESPONDENCE':
                return this.context.t('Correspondence')
            case 'OTHER':
                return this.context.t('Other')
            case 'PROTOCOL':
                return this.context.t('Protocol')
            default:
                return this.context.t('Other')
        }
    }

    selectDocType = (type) => {
        this.props.selectDocType(type)

        this.setState({
            showDocumentTypeList: false
        })
    }

    togglePaymentMethodList = () => {
        this.setState({
            showPaymentMethodList: !this.state.showPaymentMethodList
        })
    }

    selectPaymentMethod = (method) => {
        this.props.selectPaymentMethod(method)

        this.setState({
            showPaymentMethodList: false
        })
    }

    toggleExpenseUsersList = (projectId) => {
        const { showExpenseUsersList, projectMembers } = this.state

        if (!showExpenseUsersList && projectMembers.length === 0) {
            this.props.getProjectMembersForExpense(projectId, members => {
                if (members) {
                    this.setState({
                        projectMembers: members
                    })
                } else {
                    this.setState({
                        showExpenseUsersList: false
                    })
                }
            })
        }

        this.setState({
            showExpenseUsersList: !showExpenseUsersList
        })
    }

    selectExpenseOwner = (e, ftvId, dbId, userId, userName, projectId) => {
        this.props.selectExpenseOwner(e, ftvId, dbId, userId, userName, projectId)

        this.setState({
            showExpenseUsersList: false
        })
    }

    toggleCurrncyList = () => {
        this.setState({
            showCurrencyList: !this.state.showCurrencyList
        })
    }

    selectCurrency = (currency) => {
        this.props.selectCurrency(currency)

        this.setState({
            showCurrencyList: false
        })
    }

    togglePaymentTypeList = () => {
        this.setState({
            showPaymentTypeList: !this.state.showPaymentTypeList
        })
    }

    selectPaymentType = (paymentType) => {
        this.props.selectPaymentType(paymentType)

        this.setState({
            showPaymentTypeList: false
        })
    }

    togglePriorityList = () => {
        this.setState({
            showPriority: !this.state.showPriority
        })
    }

    selectPriority = (priority) => {
        this.props.selectPriority(priority)

        this.setState({
            showPriority: false
        })
    }

    render() {
        const { showDocumentTypeList, showPaymentMethodList, showExpenseUsersList, projectMembers, showCurrencyList, showPaymentTypeList, showPriority } = this.state
        const { currentInvoiceImageData, paymentMethod, currentProjectRole, currency, paymentType, priority, user } = this.props
        return (
            <div className="doc-questions-container" ref={this.props.setPropsRef}>
                <div className="doc-questions" ref={this.containerRef}>
                    <div id="doc-type-question" className="choice-short">
                        <div className="question">
                            {this.context.t('Document type ')}
                        </div>
                        <div className={`choosen-option doc-types ${showDocumentTypeList ? 'extended' : ''}`} onClick={() => this.toggleDocumentTypeList()}>
                            {
                                this.getDocTypeLabel(currentInvoiceImageData.docType)
                            }

                            {
                                showDocumentTypeList ? (
                                    <ul>
                                        <li onClick={e => this.selectDocType('COSTS')}>
                                            {this.context.t('Cost')}
                                            <span className="type-description">{this.context.t('Invoice or bill for the company')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('EXPENSES')}>
                                            {this.context.t('Expense')}
                                            <span className="type-description">{this.context.t('Receipt or invoice for the employee')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('INCOME')}>
                                            {this.context.t('Income doc')}
                                            <span className="type-description">{this.context.t('Your sales invoice')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('AGREEMENT')}>
                                            {this.context.t('Agreement')}
                                            <span className="type-description">{this.context.t('Your contract with the supplier/recipient')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('CORRESPONDENCE')}>
                                            {this.context.t('Correspondence')}
                                            <span className="type-description">{this.context.t('letter or business email')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('CORPORATE')}>
                                            {this.context.t('Corporate')}
                                            <span className="type-description">{this.context.t('official document')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('MAG')}>
                                            {this.context.t('Warehouse doc')}
                                        </li>
                                        <li onClick={e => this.selectDocType('OTHER')}>
                                            {this.context.t('Other')}
                                        </li>
                                        <li onClick={e => this.selectDocType('PROTOCOL')}>
                                            {this.context.t('Protocol')}
                                        </li>
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>

                    {
                        currentInvoiceImageData.docType && ['COSTS', 'EXPENSES_REPORT', 'MAG', 'INCOME'].includes(currentInvoiceImageData.docType) ? (
                            <div id="payment-method-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Payment status')}
                                </div>
                                <div className={`choosen-option payment-method ${showPaymentMethodList ? 'extended' : ''}`} onClick={() => this.togglePaymentMethodList()}>
                                    {paymentMethod === 'not-paid' ? this.context.t('Not paid') : this.context.t('Paid - ') + this.context.t(this.toCapitalCase(paymentMethod))}

                                    {
                                        showPaymentMethodList ? (
                                            <ul>
                                                <li onClick={e => this.selectPaymentMethod('not-paid')}>
                                                    {this.context.t('Not paid')}
                                                </li>
                                                {
                                                    paymentMethodsList.map(method => {
                                                        return (
                                                            <li onClick={e => this.selectPaymentMethod(method.value)}>
                                                                {this.context.t(`Paid - ${method.title}`)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        currentInvoiceImageData.docType && currentInvoiceImageData.docType === 'EXPENSES' && currentProjectRole !== 'USER1' ? (
                            <div id="expense-owner-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Expense owner')}
                                </div>
                                <div className={`choosen-option expense-owner ${showExpenseUsersList ? 'extended' : ''}`} onClick={() => this.toggleExpenseUsersList(currentInvoiceImageData.projectId)}>
                                    {currentInvoiceImageData.expenseOwnerName}

                                    {
                                        showExpenseUsersList ? (
                                            projectMembers.length > 0 ? (
                                                <ul>
                                                    <SimpleBar style={{ maxHeight: '400px', width: '100%', minWidth: '100%', paddingRight: '5px'}}>
                                                        {
                                                            projectMembers.map((user, i) => {
                                                                return (
                                                                    <div className={`project-member ${projectMembers[i + 1] === undefined ? 'last' : ''} ${!user.is_service && projectMembers[i + 1]?.is_service ? 'next-is-service' : ''}`} onClick={e => this.selectExpenseOwner(e, currentInvoiceImageData.ftvId, currentInvoiceImageData.dbId, user.id, user.name, currentInvoiceImageData.projectId)}>{user.name}</div>
                                                                )
                                                            })
                                                        }
                                                    </SimpleBar>
                                                </ul>
                                            ) : (
                                                <div className="waiting-for-members">
                                                    <Spinner size={20} speed={0.8} color={"#9099A5"} />
                                                </div>
                                            )
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {

                        (currentInvoiceImageData.docType && ['COSTS', 'EXPENSES_REPORT', 'MAG', 'INCOME', 'EXPENSES'].includes(currentInvoiceImageData.docType) && paymentMethod && currency) ? (
                            <div id="currency-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Currency')}
                                </div>
                                <div className={`choosen-option ${showCurrencyList ? 'extended' : ''}`} onClick={() => this.toggleCurrncyList()}>
                                    {currency}
                                    {
                                        showCurrencyList ? (
                                            <ul>
                                                {
                                                    currencyList.map(c => {
                                                        return (
                                                            <li className={`${currency === c ? 'selected' : ''}`} onClick={e => this.selectCurrency(c)}>{c}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        currentInvoiceImageData.docType && ['COSTS', 'EXPENSES_REPORT', 'MAG', 'INCOME'].includes(currentInvoiceImageData.docType) && paymentMethod === 'not-paid' ? (
                            <div id="payment-type-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Payment type')}
                                </div>
                                <div className={`choosen-option ${showPaymentTypeList ? 'extended' : ''}`} onClick={() => this.togglePaymentTypeList()}>
                                    {this.context.t(this.toCapitalCase(paymentType))}
                                    {
                                        showPaymentTypeList ? (
                                            <ul>
                                                <li onClick={e => this.selectPaymentType('regular')}>{this.context.t('Regular')}</li>
                                                <li onClick={e => this.selectPaymentType('split')}>{this.context.t('Split-payment')}</li>
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        currentInvoiceImageData.docType && ['COSTS', 'EXPENSES_REPORT', 'MAG', 'EXPENSES'].includes(currentInvoiceImageData.docType) && paymentMethod === 'not-paid' ? (
                            <div id="priority-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Priority')}
                                </div>
                                <div className={`choosen-option ${showPriority ? 'extended' : ''}`} style={{ minWidth: 100, textAlign: 'right' }} onClick={() => this.togglePriorityList()}>
                                    {this.context.t(priority === 'high' ? 'Important' : 'Normal')}
                                    {
                                        showPriority ? (
                                            <ul style={{ width: '100%', textAlign: 'left' }}>
                                                <li onClick={e => this.selectPriority('regular')}>{this.context.t('Normal')}</li>
                                                <li onClick={e => this.selectPriority('high')}>{this.context.t('Important')}</li>
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>
            </div>
        )
    }
}

OcrPageDocQuestions.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
})

const mapDispatchToProps = {
    getProjectMembersForExpense: FileActions.getProjectMembersForExpense,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageDocQuestions)