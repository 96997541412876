import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import { Spinner } from 'react-activity'
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { ChatActions } from '../../actions/ChatAction';

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentNote: "",
      noteToSend: "",
      selectedUsers: [],
      prepareUserList: [],
    };

    this.messagesEndRef = createRef();
  }

  componentDidMount() {
    const { fileId } = this.props;

    if (fileId) {
      this.loadChatForFile()
    }
  }

  componentDidUpdate(prevProps) {
    const { fileId, allEntries, showChatLoading, usersList } = this.props;

    if (fileId !== prevProps.fileId) {
      this.loadChatForFile()
    }

    if (prevProps.allEntries !== allEntries && allEntries.length > 0) {
      this.props.openChat(true)
      this.scrollToBottom();
    } else if (prevProps.allEntries !== allEntries && allEntries.length === 0 && !showChatLoading) {
      this.props.closeChat(false)
    }

    if (prevProps.usersList !== usersList) {
      this.readyUserList();
    }
  }

  loadChatForFile = (clearPrev) => {
    const { fileId } = this.props;
    this.props.getAllEntries(fileId)
    this.props.getFileById(fileId)
    this.props.addUsersList(fileId)

    // Oznaczenie konwersacji jako przeczytanej w momencie otwierania czatu
    this.props.markFileChatAsRead(fileId)
  }

  handleChange = (event, newValue, newPlainTextValue) => {
    const value = event.target.value;
    const regex = /[^{}]+(?=})/g;
    const mention = value.match(regex);

    this.setState({
      selectedUsers: mention,
      currentNote: value,
      noteToSend: newPlainTextValue,
    });
  };

  readyUserList = () => {
    const { usersList, currentUser } = this.props;

    if (usersList.length > 0) {
      const UserList = usersList
        .filter((user) => user.id !== currentUser.id)
        .map((user) => {
          return {
            id: user.id,
            display: `${user.first_name} ${user.last_name}`,
          };
        });

      this.setState({ prepareUserList: UserList });
    }
  };

  handleSubmitForm = (e) => {
    e.preventDefault();

    const { fileId, dispatch, currentUser } = this.props;
    const { noteToSend, selectedUsers } = this.state;

    if (!noteToSend) return;

    if (selectedUsers && selectedUsers.length > 0) {
      const mentionedUsers = selectedUsers.map((el) => {
        return { id: +el };
      });
      this.props.sendEntry(fileId, noteToSend, mentionedUsers, currentUser.id)
    } else {
      this.props.sendEntry(fileId, noteToSend, null, currentUser.id)
    }

    this.setState({
      currentNote: "",
      noteToSend: "",
      selectedUsers: [],
    });
  };

  close = () => {
    this.props.closeChat(false)
  };

  scrollToBottom = () => {
    this.messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  onTextAreaKeyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.handleSubmitForm(e);
    }
  };

  focusOnChatTextarea = () => {
    const { fileId } = this.props;

    if (fileId) {
      this.props.markFileChatAsRead(fileId)
    }
  };

  generateEntries = () => {
    const { allEntries, currentUser } = this.props;
    const elementsToReturn = [];
    let prevEntryCreator = -1, nextEntryCreator = -1;

    if (allEntries.length > 0) {
      allEntries.forEach((entry, index) => {
        nextEntryCreator = allEntries[index + 1]?.user_id || -1;

        if (entry.user_id === currentUser.id) {
          elementsToReturn.push(
            <DisplaySecoundEntry
              key={entry.id}
              data={entry}
              last={index === allEntries.length - 1}
              lastFromUser={nextEntryCreator !== entry.user_id}
            />
          );
        } else {
          elementsToReturn.push(
            <DisplayEntry
              key={entry.id}
              data={entry}
              first={index === 0}
              last={index === allEntries.length - 1}
              displayName={prevEntryCreator !== entry.user_id}
              displayPhoto={nextEntryCreator !== entry.user_id}
              nextIsFromOtherUser={
                nextEntryCreator !== entry.user_id &&
                nextEntryCreator !== currentUser.id
              }
            />
          );
        }

        prevEntryCreator = entry.user_id;
      });
    }

    return elementsToReturn;
  };

  render() {
    const { shrinkToFitDocs, isWidget, isOpenChat, allEntries, showChatLoading } = this.props;
    const { currentNote, prepareUserList } = this.state;

    return (
      <div
        className={`chat-wrapper ${shrinkToFitDocs ? "shrink-for-linkde-docs" : ""
          } ${isWidget ? "chat-widget" : ""} ${isWidget && isOpenChat ? "chat-wrapper_open" : ""
          }`}
      >
        {isWidget && (
          <div className="close-wrapper">
            <p className="header-note">{this.context.t("Chat about the document")}</p>
            <p></p>
            <button
              className="chat-close-button"
              onClick={this.close}
              type="button"
            ></button>
          </div>
        )}

        <div id="chat" className="chat-container">
          {
            showChatLoading ? (
              <div className="chat-loading">
                <Spinner size={30} speed={0.8} color={"#444444"} />
              </div>
            ) : (
              <>
                {
                  allEntries.length === 0 && (
                    <div className="no-notes-wraper">
                      <div className="no-notes-icon"></div>
                      <p className="start-note">
                        {this.context.t("Add a message to the document,")}
                      </p>
                      <p className="start-note">{this.context.t("use @ to call a person")}</p>
                    </div>
                  )
                }

                {this.generateEntries(allEntries)}

                <div ref={this.messagesEndRef} />
              </>
            )
          }
        </div>

        <div className="line"></div>
        <div className="form-container">
          <form onSubmit={this.handleSubmitForm}>
            <MentionsInput
              value={currentNote}
              style={defaultStyle}
              onChange={this.handleChange}
              onKeyDown={this.onTextAreaKeyPress}
              onFocus={this.focusOnChatTextarea}
            >
              <Mention
                trigger="@"
                markup="[__display__]{__id__}"
                data={prepareUserList}
                appendSpaceOnAdd={true}
                displayTransform={(id, display) => `@${display}`}
              />
            </MentionsInput>
            <button id="send" className="sent-button" type="submit"></button>
          </form>
        </div>
      </div >
    );
  }
}

Chat.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: state.User.user,
  showChatLoading: state.Chat.showChatLoading,
  allEntries: state.Chat.entries,
  isOpenChat: state.Chat.isOpenChat,
  usersList: state.Chat.usersList,
});

const mapDispatchToProps = {
  addUsersList: ChatActions.addUsersList,
  clearEntry: ChatActions.clearEntry,
  closeChat: ChatActions.closeChat,
  getAllEntries: ChatActions.getAllEntries,
  getFileById: ChatActions.getFileById,
  sendEntry: ChatActions.sendEntry,
  clearUserList: ChatActions.clearUserList,
  markFileChatAsRead: ChatActions.markFileChatAsRead,
  openChat: ChatActions.openChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

function DisplayEntry({
  data,
  first,
  last,
  displayName,
  displayPhoto,
  nextIsFromOtherUser,
}) {
  const { created, id, entry, status, creator } = data;
  const initialsOrPic = creator?.profile_pic_link
    ? " "
    : `${creator?.first_name.slice(0, 1)}${creator?.last_name.slice(0, 1)}`;

  return (
    <div
      className={`entry-container ${last ? "last-entry" : ""} ${displayPhoto && !nextIsFromOtherUser ? "last-from-user" : ""
        } ${!displayPhoto ? "from-same-user" : ""}`}
    >
      {displayPhoto ? (
        <div
          className="initials"
          style={{
            backgroundImage: creator.profile_pic_link
              ? `url('${creator.profile_pic_link}')`
              : ``,
          }}
        >
          <div className="initials-symbol">{initialsOrPic}</div>
        </div>
      ) : null}
      <div className="note-container">
        {displayName ? (
          <p className={`name ${first ? "first-message" : ""}`}>
            {`${creator?.first_name} ${creator?.last_name}, ${moment(created).format("YYYY-MM-DD HH:mm")}`}</p>
        ) : null}
        <div data-for={`entry-tooltip-${id}`} data-tip="show" className="note">
          {entry}
        </div>

        <ReactTooltip
          id={`entry-tooltip-${id}`}
          place="left"
          effect="solid"
          className="default-tooltip chat-tooltip"
        >
          <p>{moment(created).format("DD-MM-YYYY HH:mm")}</p>
          {/* <p>{`Status: ${status}`}</p> */}
        </ReactTooltip>
      </div>
    </div>
  );
}

function DisplaySecoundEntry({ data, last, lastFromUser }) {
  const { id, created, entry } = data;
  return (
    <div
      className={`entry-container secound ${last ? "last-entry" : ""} ${lastFromUser ? "last-from-user" : ""
        }`}
    >
      <div
        data-for={`entry-tooltip-${id}`}
        data-tip="show"
        className="note secound"
      >
        {entry}
      </div>

      <ReactTooltip
        id={`entry-tooltip-${id}`}
        place="left"
        effect="solid"
        className="default-tooltip chat-tooltip"
      >
        <p>{moment(created).format("DD-MM-YYYY HH:mm")}</p>
        {/* <p>{`Status: ${status}`}</p> */}
      </ReactTooltip>
    </div>
  );
}

const defaultStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 12,
    borderRadius: 4,
    fontWeight: "normal",
    width: 210,
  },
  highlighter: {
    boxSizing: "border-box",
    overflow: "hidden",
  },
  input: {
    margin: 0,
    overflow: "auto",
  },
  "&multiLine": {
    control: {
      fontFamily: "Open Sans",
      border: "1px solid #e7e9ed",
    },
    highlighter: {
      padding: 9,
      maxHeight: 75,
    },
    input: {
      padding: 9,
      minHeight: 3,
      outline: 0,
      border: 0,
      maxHeight: 75,
    },
  },
  suggestions: {
    bottom: 30,
    top: "unset",
    list: {
      backgroundColor: "white",
      border: "1px solid #e7e9ed",
      fontSize: 12,
    },
    item: {
      padding: "5px 15px",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
