import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-textarea-autosize'

import { FileActions } from '../../actions/FileActions'
import { ReportsActions } from '../../actions/ReportsActions'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import waproUsers from '../../helpers/waproUsers.json'
import magDocTypeList from '../../helpers/magDocTypes.json'
import fakirDocTypeList from '../../helpers/fakirDocTypes.json'
import fakirDictionaryList from '../../helpers/fakirDocDictionary.json'
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'


import AvailableInOtherPlanLabel from '../AvailableInOtherPlanLabel'
import FileAcceptanceStepsSection from './FileAcceptanceStepsSection'
import PreviewCounterpartyEdit from './PreviewCounterpartyEdit'
import DropDownList from '../DropDownList'
import CountriesList from '../CountriesList'
import TagsList from '../TagsList'

export class IncomesFrom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTagsList: false,
            showCurrencyList: false,
            showMagDocType: false,
            showFakirDocType: false,
            showFakirDictionary: false,
            showWaproNetAccoutList: false,
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showTagsList: false,
                showCurrencyList: false,
                showMagDocType: false,
                showFakirDocType: false,
                showFakirDictionary: false,
                showWaproNetAccoutList: false,
            })
        } else {
            if (event.keyCode === 27 || !this.hasSomeParentTheClass(event.target, 'payment-type-container')) {
                this.setState({
                    showPaymentTypesList: false
                })
            }

            if (event.keyCode === 27 || !this.hasSomeParentTheClass(event.target, 'priority-container')) {
                this.setState({
                    showPriorityList: false
                })
            }

            if (event.keyCode === 27 || (this.state.showTagsList && !this.hasSomeParentTheClass(event.target, 'tags-container'))) {
                this.setState({
                    showTagsList: false
                })
            }

            if (event.keyCode === 27 || (this.state.showMagDocType && !this.hasSomeParentTheClass(event.target, 'wapro-mag-container'))) {
                this.toggleMagDocTypeList(false)
            }

            if (event.keyCode === 27 || (this.state.showFakirDocType && !this.hasSomeParentTheClass(event.target, 'wapro-fakir-type-container'))) {
                this.toggleFakirDocTypeList(false)
            }

            if (event.keyCode === 27 || (this.state.showFakirDictionary && !this.hasSomeParentTheClass(event.target, 'wapro-fakir-dictionary-container'))) {
                this.toggleFakirDictionaryList(false)
            }

            if (event.keyCode === 27 || (this.state.showWaproNetAccoutList && !this.hasSomeParentTheClass(event.target, 'wapro-net-account-container'))) {
                this.toggleWaproNetAccoutList(false)
            }
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    changeInputValue = (e, field, docType, docId) => {
        e.preventDefault()
        this.props.editDocInput(e.target.value, field, docType, docId)
    }

    getDocStatus = (pdfPreviewData, acceptancePermissions) => {

        if (pdfPreviewData.status === 'NEW')
            return (<span className="uneditable-prop">{this.context.t('OCR not submited')}</span>)

        if (pdfPreviewData.status === 'NOT_VERIFIED')
            return (<span className="uneditable-prop">{this.context.t('OCR not verified')}</span>)

        if (pdfPreviewData.status === 'IN_PROGRESS' && pdfPreviewData.acceptanceStep < 3)
            return (<span className="uneditable-prop">{this.context.t('OCR not accepted')}</span>)

        if (pdfPreviewData.acceptanceStep === 3) {
            if (acceptancePermissions !== 'USER1' && !pdfPreviewData.deleted)
                return (
                    <>
                        <span className="uneditable-prop">{this.context.t('accepted')}</span>
                        <span className="mark-as-paid-btn" data-tip='custom show' data-event='click' data-for='payment-methods-tooltip'>{this.context.t('Mark as paid')}</span>
                        <ReactTooltip ref='paymentTooltipRef' id='payment-methods-tooltip' className='default-tooltip payment-methods-tooltip' effect='solid' place='bottom' globalEventOff="click">
                            <ul>
                                {
                                    paymentMethodsList.map(method => {
                                        return (
                                            <li onClick={e => this.paymentMethodClick(method.value)}>
                                                {this.context.t(method.title)}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </ReactTooltip>
                    </>
                )

            return (<span className="uneditable-prop">{this.context.t('accepted')}</span>)

        }
        return (
            <span className="uneditable-prop">
                {this.context.t('paid')}
                {pdfPreviewData.paymentMethod ? ` (${this.context.t(pdfPreviewData.paymentMethod)})` : ''}
                {
                    pdfPreviewData.paidBy ? (
                        <>
                            <br /> {`${this.context.t(pdfPreviewData.paidBy)} - ${moment(pdfPreviewData.paymentDate).format('YYYY-MM-DD HH:mm')}`}
                        </>
                    ) : (
                        null
                    )
                }
            </span>
        )
    }

    paymentMethodClick = method => {
        const { pdfPreviewData } = this.props
        this.props.tooglePaymentForIncomeDoc(pdfPreviewData.docId, pdfPreviewData.id, pdfPreviewData.projectId, method);

        if (this.refs.paymentTooltipRef) {
            this.refs.paymentTooltipRef.tooltipRef = null;
            ReactTooltip.hide()
        }

        this.props.editDocInput(method, 'paymentMethod', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(4, 'acceptanceStep', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput('DONE', 'status', pdfPreviewData.docType, pdfPreviewData.docId)

        if (this.props.editMode) {
            this.props.changePaymentMethodInPdfBackup(method)
        }
    }

    showTagsList = () => {
        this.setState({
            showTagsList: true
        })
    }

    returnTags = (tags, docId, docType) => {
        this.props.editDocInput(tags, 'tags', docType, docId)
    }

    selectPriority = (name, docType, docId) => {
        if (this.props.editMode) {
            this.setState({
                showPriorityList: false
            })
            this.props.editDocInput(name, 'priority', docType, docId)
        }
    }

    showCurrencyList = (triger) => {
        this.setState({
            showCurrencyList: !this.state.showCurrencyList,
            currencyTriger: triger
        })
    }

    selectCurrency = (name, docType, docId) => {
        this.setState({
            showCurrencyList: false
        })
        this.props.editDocInput(name, 'currency', docType, docId)
    }

    toggleMagDocTypeList = show => {
        this.setState({
            showMagDocType: show !== undefined ? show : !this.state.showMagDocType
        })
    }

    selectMagDocType = (value) => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'waproMagType', pdfPreviewData.docType, pdfPreviewData.docId)
        this.toggleMagDocTypeList(false)
    }

    toggleFakirDocTypeList = show => {
        this.setState({
            showFakirDocType: show !== undefined ? show : !this.state.showFakirDocType
        })
    }

    selectFakirDocType = (value) => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'waproFakirType', pdfPreviewData.docType, pdfPreviewData.docId)
        this.toggleFakirDocTypeList(false)
    }

    toggleFakirDictionaryList = show => {
        this.setState({
            showFakirDictionary: show !== undefined ? show : !this.state.showFakirDictionary
        })
    }

    selectFakirDictionary = (value) => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'waproFakirDictionary', pdfPreviewData.docType, pdfPreviewData.docId)
        this.toggleFakirDictionaryList(false)
    }

    toggleWaproNetAccoutList = show => {
        this.setState({
            showWaproNetAccoutList: show !== undefined ? show : !this.state.showWaproNetAccoutList
        })
    }

    selectNetAccount = (value) => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'waproNetAccout', pdfPreviewData.docType, pdfPreviewData.docId)
        this.toggleWaproNetAccoutList(false)
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return '0.00'
        }
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    showPaymentTypesList = () => {
        if (this.props.editMode) {
            this.setState({
                showPaymentTypesList: !this.state.showPaymentTypesList
            })
        }
    }

    selectPaymentType = (name, docType, docId) => {
        if (this.props.editMode) {
            this.setState({
                showPaymentTypesList: false
            })
            this.props.editDocInput(name, 'paymentType', docType, docId)
        }
    }

    render() {
        const { showTagsList, showCurrencyList, currencyTriger, showMagDocType, showFakirDocType, showFakirDictionary, showWaproNetAccoutList } = this.state
        const { user, pdfPreviewData, acceptancePermissions, editMode, docOwnerCompany, tagsList, netAccountList } = this.props
        return (
            <div onClick={e => this.handleClick(e)} ref={this.wrapperRef}>
                <FileAcceptanceStepsSection pdfPreviewData={pdfPreviewData} />

                {
                    pdfPreviewData.deleted ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Date of permanent removal:')}</span>
                            <span className="uneditable-prop">{moment(pdfPreviewData.deletedDate).add(45, 'days').format('DD-MM-YYYY')}</span>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="doc-prop">
                    <span className="name">{this.context.t('Document status:')}</span>
                    {this.getDocStatus(pdfPreviewData, acceptancePermissions)}
                </div>

                <div className="doc-prop">
                    <span className="name">{this.context.t('Comment:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        //style={{padding: editMode ? '10px' : '0px'}}
                        readOnly={!editMode}
                        name="comment"
                        id={`commentInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.comment}
                        onChange={(e) => this.changeInputValue(e, 'comment', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Document description')}:</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        //style={{padding: editMode ? '10px' : '0px'}}
                        readOnly={!editMode}
                        name="doc_description"
                        id={`doc_descriptionInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.doc_description}
                        onChange={(e) => this.changeInputValue(e, 'doc_description', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop tags-container">
                    {/* <AvailableInOtherPlanLabel planName='PRO' labelSize="LABEL-SHADOW" company={docOwnerCompany} /> */}
                    <span className="name">{this.context.t('Tags:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''} ${showTagsList ? 'focused' : ''}`}
                        readOnly={!editMode}
                        name="tags"
                        id={`tagsInput-${pdfPreviewData.docId}`}
                        placeholder={this.context.t('e.g. leasing, car')}
                        value={pdfPreviewData.tags}
                        onChange={(e) => this.changeInputValue(e, 'tags', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={() => this.showTagsList()}
                    />
                    {
                        showTagsList && tagsList && tagsList.length > 0 && editMode ? (
                            <TagsList companyTags={tagsList} selectedTags={pdfPreviewData.tags} returnTags={this.returnTags} docId={pdfPreviewData.docId} docType={pdfPreviewData.docType} />
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Document number:')}</span>
                    <TextareaAutosize
                        onHeightChange={(height, instance) => console.log('teaxtarea height change ' + height, instance.rowCount)}
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="invoiceNumber"
                        id={`invoiceNumberInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.invoiceNumber}
                        onChange={(e) => this.changeInputValue(e, 'invoiceNumber', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <h5>{this.context.t('Seller')}</h5>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Name')}:</span>
                    <TextareaAutosize
                        className={`read-only`}
                        readOnly={true}
                        name="seller"
                        id={`sellerInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.companyName}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('VatID')}:</span>
                    <TextareaAutosize
                        className={`read-only`}
                        readOnly={true}
                        name="sellerVatId"
                        id={`sellerVatIdInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.companyVatId}
                    />
                </div>

                <PreviewCounterpartyEdit
                    titleText={'Buyer'}
                    marginBottom={true}
                    marginTop={true}
                    editMode={editMode}
                />

                <div className="doc-prop">
                    <span className="name">{this.context.t('Net:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}

                        readOnly={!editMode} name="netto"
                        id={`nettoInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.netto)) + " " + pdfPreviewData.currency) : pdfPreviewData.netto}
                        onChange={e => this.changeInputValue(e, 'netto', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Gross:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode} name="brutto"
                        id={`bruttoInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.brutto)) + " " + pdfPreviewData.currency) : pdfPreviewData.brutto}
                        onChange={e => this.changeInputValue(e, 'brutto', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Amount to pay:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="price"
                        id={`priceInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.price)) + " " + pdfPreviewData.currency) : pdfPreviewData.price}
                        onChange={e => this.changeInputValue(e, 'price', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                    <span className={`currency-selector ${!editMode ? 'hide' : ''} ${showCurrencyList ? 'list-expended' : ''} z-index-3`}>
                        <span className='current-currency' onClick={() => this.showCurrencyList('T')}>{pdfPreviewData.currency}</span>
                        <span className={`arrow ${showCurrencyList && currencyTriger === 'T' ? 'expanded' : ''} currency-arrow`} onClick={() => this.showCurrencyList('T')}></span>
                        <ul className={`currency-list ${showCurrencyList && currencyTriger === 'T' ? 'expanded' : 'hide'}`}>
                            {
                                currencyList.map(c => {
                                    return (
                                        <li className={`${pdfPreviewData.currency === c ? 'selected' : ''}`} onClick={() => this.selectCurrency(c, pdfPreviewData.docType, pdfPreviewData.docId)}>{c}</li>
                                    )
                                })
                            }
                        </ul>
                    </span>
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Account number:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="accountNumber"
                        id={`accountNumberInput-${pdfPreviewData.docId}`}
                        value={this.polihAccountPritier(pdfPreviewData.accountNumber)}
                        onChange={e => this.changeInputValue(e, 'accountNumber', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop issue-date-container">
                    <span className="name">{this.context.t('Issue date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={true}
                        name="issueDate"
                        id={`issueDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.issueDate}
                        onChange={e => this.changeInputValue(e, 'issueDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showIssueDateDocCalendar(e)}
                    />
                </div>
                <div className="doc-prop due-date-container">
                    <span className="name">{this.context.t('Due date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={true}
                        name="dueDate"
                        id={`dueDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.dueDate}
                        onChange={e => this.changeInputValue(e, 'dueDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showDueDateDocCalendar(e)}
                    />
                </div>

                {
                    user.id === 2 || user.id === 111 || user.id === 232 ? (
                        <>
                            <span className="separator wapro">{this.context.t('Required fields for documents imported to WAPRO Fakir')}</span>

                            <div className="doc-prop wapro-mag-container">
                                <span className="name">{this.context.t('Mag type:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproMagType"
                                    id={`waproMagType-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.magType || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.magType', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleMagDocTypeList(true)}
                                />
                                {
                                    showMagDocType && editMode ? (
                                        <DropDownList
                                            list={magDocTypeList}
                                            selectedOption={pdfPreviewData.waproData?.magType || ''}
                                            select={this.selectMagDocType}
                                            headerText={this.context.t('Available types:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className="doc-prop  wapro-fakir-type-container">
                                <span className="name">{this.context.t('Fakir type:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproFakirType"
                                    id={`waproFakirType-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.fakirType || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.fakirType', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleFakirDocTypeList(true)}
                                />
                                {
                                    showFakirDocType && editMode ? (
                                        <DropDownList
                                            list={fakirDocTypeList}
                                            selectedOption={pdfPreviewData.waproData?.fakirType || ''}
                                            select={this.selectFakirDocType}
                                            headerText={this.context.t('Available types:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className="doc-prop wapro-fakir-dictionary-container">
                                <span className="name">{this.context.t('Fakir dictionary:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproFakirDictionary"
                                    id={`waproFakirDictionary-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.fakirDictionary || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.fakirDictionary', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleFakirDictionaryList(true)}
                                />
                                {
                                    showFakirDictionary && editMode ? (
                                        <DropDownList
                                            list={fakirDictionaryList}
                                            selectedOption={pdfPreviewData.waproData?.fakirDictionary || ''}
                                            select={this.selectFakirDictionary}
                                            headerText={this.context.t('Available logs:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className="doc-prop wapro-net-account-container">
                                <span className="name">{this.context.t('Fakir net account:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproNetAccout"
                                    id={`waproNetAccout-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.netAccount || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.netAccount', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleWaproNetAccoutList(true)}
                                />

                                {
                                    showWaproNetAccoutList && netAccountList && netAccountList.length > 0 && editMode ? (
                                        <DropDownList
                                            list={netAccountList}
                                            selectedOption={pdfPreviewData.waproData?.netAccount || ''}
                                            select={this.selectNetAccount}
                                            headerText={this.context.t('Available net accounts:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className="doc-prop wapro-doc-description-container">
                                <span className="name">{this.context.t('Description:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    name="docDescription"
                                    id={`docDescription-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.docDescription}
                                    onChange={e => this.changeInputValue(e, 'waproData.docDescription', pdfPreviewData.docType, pdfPreviewData.docId)}
                                />
                            </div>

                            <div className="accounted-doc">
                                <span className={`checkbox ${pdfPreviewData.cashAccountingScheme ? 'checked' : ''}`} onClick={e => editMode ? this.props.editDocInput(!pdfPreviewData.cashAccountingScheme, 'cashAccountingScheme', pdfPreviewData.docType, pdfPreviewData.docId) : null}></span>
                                <p onClick={e => editMode ? this.props.editDocInput(!pdfPreviewData.cashAccountingScheme, 'cashAccountingScheme', pdfPreviewData.docType, pdfPreviewData.docId) : null} style={{ width: 'fit-content' }}>
                                    {this.context.t("Metoda kasowa")}
                                </p>
                            </div>
                        </>
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

IncomesFrom.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewData: state.File.pdfPreviewData,
    user: state.User.user,
})

const mapDispatchToProps = {
    changePaymentMethodInPdfBackup: FileActions.changePaymentMethodInPdfBackup,
    editDocInput: FileActions.editDocInput,
    toogleAdditionalAcceptanceForDoc: ReportsActions.toogleAdditionalAcceptanceForDoc,
    tooglePaymentForIncomeDoc: ReportsActions.tooglePaymentForIncomeDoc
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomesFrom)