import myFetch from "../helpers/fetch"

import { UserConst } from "../reducers/UserReducer"
import { FileConst } from "../reducers/FileReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { AlertActions } from "./AlertActions"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"


export const CompanyActions = {
  getAllCompanies,
  sortAllCompaniesForSuperadmin,
  updateCompanyData,

  addNewBankForCompany,
  updateBankInfo,
  deleteBankFromCompany,

  addNewInvoiceNumberingSeries,
  updateInvoiceNumberingSeries,
  deleteInvoiceNumberingSeries,

  changeCompanyRepresentative,
  promoteUser,
  getAllCompanyProjects,
  changeMyRoleInProject,
  getAllCompanyCounterparties,
  updateCounterparty,
  addCounterparty,
  mergeCounterparties,
  importCounterparties,
  switchCounterpartiesSortingFilter,
  getAllCompanyWorkers,
  switchCompanyMembersSorting,
  saveProjectWorkers,
  getProjectWorkers,
  getUserCompanies,
  setCurrentCompany,
  findCompanyByVatId,

  deleteCompanyMember,
  changeUserRole,

  changeUsersRoleInProject,
  toggleAdditionalAprovalForDocs,
  toggleAdditionalAprovalForDocsUnderTreshold,
  changeAdditionalDocsAcceptanceTresholds,
  saveUsersForAdditionalAcceptance,
  getCurrentProjectAcceptanceConfiguration,
  setCurrentProjectAcceptanceConfiguration,

  removeUsersFromProject,
  inviteAndAssignMember,
  resetInviteNewUserMessage,
  chackeIfProjectCodeIsUnique,
  checkIfCompanyWithNipExists,
  registerCompany,
  loadNewCompanyTags,
  registerSubscriptionPayment,
  updateCompanySubscription,
  cancelSubscription,
  changeCompanySubscriptionType,
  getCompanysubscriptionTransactions,
  switchTransactionsSortingFilter,
  getMyLatestSubsctiptionPayment,
  closePopupAndDeleteInvoiceNumber,
  handleLoadScreen,
  makeEspagoPayment,
  manualPaymentClick,

  getDocumentNumberForNewInvoice,
  CheckCorrectInvoiceNumberforCompany,
  getSavedDocItems,
  saveNewInvoiceItem,
  editInvoiceItem,
  deleteDocItem,
  getSavedRecurringInvoice,
  generateExamplePdf,
  createRecurringInvoice,
  saveChangesToRecurringInvoice,
  deleteRecurringInvoice,
  getPrieviosGeneratedInvoices,
  downloadGeneratedInvoice,
  deleteGeneratedInvoice,
  acceptGeneratedSaleInvoice,
  unlockSaleInvoice,
  generateInvoice,

  getNewGeneratedInvoice,
  editInvoice,
  sendInvoiceByEmail,
  importInvoice,
  getAllCorrectionsToInvoice,
  getInvoiceImportExcelFile,
  sendInvoiceToKSeF,
  generateJPK_FA,

  getCompanyWaproNetAccounts,
  getCompanySymfoniaFile,
  getOriginalDocNumbersForCounterparty,
  getAllDocsFromCounterparty,
  getProjectAgreements,

  changeCompanyLogo,
  deleteCompanyLogo,
  registerNextCompany,
  generateDuplicate
}

function checkIfCompanyWithNipExists(nip) {
  return (dispatch) => {
    dispatch({ type: CompanyConst.SEND_CHECK_NIP_REQUEST });
    myFetch(`/companies/check-uniqueness/${nip}`, { method: "get" })
      .then((res) => {
        dispatch({
          type: CompanyConst.CHECK_NIP,
          isNewNipUnique: res.unique,
          registrationToken: res.registrationToken,
          newCompanyId: res.newCompanyId,
        });
        if (res.unique) {
          localStorage.setItem("companyToRegisterId", res.newCompanyId);
          localStorage.setItem("companyToRegisterTokrn", res.registrationToken);
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/check-uniqueness/${nip}`);
      });
  };
}

function registerCompany(nip, companyName, email, campaign, usersLimit, language, subType, cb) {
  return dispatch => {
    dispatch({ type: CompanyConst.SEND_CHECK_NIP_REQUEST })
    dispatch({ type: CompanyConst.SEND_REGISTER_COMPANY_REQUEST })

    myFetch(`/companies/check-uniqueness/${nip}`, { method: 'get' }).then(res => {
      dispatch({ type: CompanyConst.CHECK_NIP, isNewNipUnique: res.unique, registrationToken: res.registrationToken, newCompanyId: res.newCompanyId })
      if (res.unique) {
        myFetch(`/companies/register-company/`, {
          method: 'post', body: {
            id: parseInt(res.newCompanyId),
            token: res.registrationToken,
            companyName: companyName,
            email: email.toLowerCase(),
            campaign: campaign,
            usersLimit: usersLimit,
            lang: language,
            subType: subType,
          }
        }).then(res => {
          cb(res.success)
          if (res.success) {
            var totalCost = subType === 'BASIC' ? 199 : subType === 'PRO' ? 299 : subType === 'PRO+' ? 599 : subType === 'FREE' ? 0 : 49
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'sign_up',
              ecommerce: {
                items: [
                  {
                    item_id: subType === 'BASIC' ? "Varian_2" : subType === 'PRO' ? 'Varian_3' : subType === 'PRO+' ? 'Varian_4' : subType === 'FREE' ? 'Varian_1' : 'Varian_5',
                    item_name: subType,
                    coupon: "Poleć Znajomemu",
                    currency: "PLN",
                    discount: 0,
                    price: parseFloat(totalCost),
                    quantity: 1
                  }]
              }
            });
            dispatch({ type: CompanyConst.GOT_REGISTER_COMPANY_RESPONSE, success: true })
          } else {
            dispatch(AlertActions.warning(res.msg))
            dispatch({ type: CompanyConst.GOT_REGISTER_COMPANY_RESPONSE, success: false })
          }
        }).catch(err => {
          cb(false)
          dispatch({ type: CompanyConst.GOT_REGISTER_COMPANY_RESPONSE, success: false })
          handleError(err, dispatch, `/companies/check-uniqueness/${nip}`)
        })
      } else {
        cb(false)
        dispatch({ type: CompanyConst.GOT_REGISTER_COMPANY_RESPONSE, success: false })
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/companies/check-uniqueness/${nip}`)
    })
  }
}

function getAllCompanies() {
  return (dispatch) => {
    myFetch(`/companies/superadmin`, { method: "get" }, false)
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.GET_ALL_COMPANIES,
            companies: res.companies,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/superadmin`);
      });
  };
}

function sortAllCompaniesForSuperadmin(field, order) {
  return (dispatch) => {
    dispatch({
      type: CompanyConst.SORT_ALL_COMPANIES,
      sortField: field,
      sortOrder: order,
    });
  };
}

function updateCompanyData(companyId, newData) {
  return (dispatch) => {
    myFetch(`/companies/update-company`, {
      method: "put",
      body: {
        companyId: companyId,
        data: newData,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/update-company`);
      });
  };
}

function addNewBankForCompany(companyId, newBankData, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/company-banks/add-bank`, {
      method: "post",
      body: {
        companyId: companyId,
        data: newBankData,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
          dispatch(AlertActions.info("Bank account created"));
          cb(true, res.newBankId);
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb(false);
        }
      })
      .catch((err) => {
        cb(false);
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/company-banks/add-bank`);
      });
  };
}

function updateBankInfo(bankId, companyId, newData) {
  return (dispatch) => {
    myFetch(`/company-banks/update-bank`, {
      method: "put",
      body: {
        bankId: bankId,
        companyId: companyId,
        data: newData,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/company-banks/update-bank`);
      });
  };
}

function deleteBankFromCompany(bankId, companyId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/company-banks/delete-bank`, {
      method: "delete",
      body: {
        bankId: bankId,
        companyId: companyId,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
          dispatch(AlertActions.info("Bank account deleted"));
          cb(true)
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb(false)
        }
      })
      .catch((err) => {
        cb(false)
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/company-banks/delete-bank`);
      });
  };
}

function addNewInvoiceNumberingSeries(companyId, newData, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/invoices-numbering-series/add-series`, {
      method: "post",
      body: {
        companyId: companyId,
        data: newData,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
          dispatch(AlertActions.info("Numbering series created"));
          cb(true, res.newSeriesId);
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb(false);
        }
      })
      .catch((err) => {
        cb(false);
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/invoices-numbering-series/add-series`);
      });
  };
}

function updateInvoiceNumberingSeries(seriesId, companyId, newData) {
  return (dispatch) => {
    myFetch(`/invoices-numbering-series/update-series`, {
      method: "put",
      body: {
        seriesId: seriesId,
        companyId: companyId,
        data: newData,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/invoices-numbering-series/update-series`);
      });
  };
}

function deleteInvoiceNumberingSeries(seriesId, companyId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/invoices-numbering-series/delete-series`, {
      method: "delete",
      body: {
        seriesId: seriesId,
        companyId: companyId,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
          dispatch(AlertActions.info("Numbering series deleted"));
          cb(true)
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb(false)
        }
      })
      .catch((err) => {
        cb(false)
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/invoices-numbering-series/delete-series`);
      });
  };
}

function changeCompanyRepresentative(companyId, userId) {
  return (dispatch) => {
    myFetch(`/companies/change-representative`, {
      method: "put",
      body: {
        companyId: companyId,
        newRepresentativeId: userId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/change-representative`);
      });
  };
}

function promoteUser(userId, companyId) {
  return (dispatch) => {
    myFetch(`/user-companies/promote-user`, {
      method: "post",
      body: {
        user_id: userId,
        company_id: companyId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: CompanyConst.PROMOTED_USER, status: true });
          dispatch(AlertActions.info("User successfully promoted to Admin"));
        } else {
          dispatch(AlertActions.danger(res.message));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/user-companies/promote-user`);
      });
  };
}

function getAllCompanyProjects(companyId) {
  return (dispatch) => {
    myFetch(`/projects/get-company-projects/${companyId}`, { method: "get" })
      .then((res) => {
        dispatch({
          type: CompanyConst.GET_COMPANY_PROJECTS,
          companyProjects: res.projects,
        });
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/projects/get-company-projects/${companyId}`
        );
      });
  };
}

function changeMyRoleInProject(projectId, role) {
  return (dispatch) => {
    dispatch({
      type: CompanyConst.CHANGE_MY_ROLE_IN_PROJECT,
      projectId: projectId,
      role: role,
    });
  };
}

function getAllCompanyCounterparties(companyId) {
  return (dispatch) => {
    myFetch(`/counterparties/get-company-counterparties/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        dispatch({
          type: CompanyConst.GET_COMPANY_COUNTERPARTIES,
          counterparties: res.counterparties,
        });
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/counterparties/get-company-counterparties/${companyId}`
        );
      });
  };
}

function updateCounterparty(companyId, counterpartyId, vatId, name, email, code, address, paymentDue, updateFiles, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/counterparties/edit`, {
      method: "put",
      body: {
        companyId: companyId,
        counterpartyId: counterpartyId,
        newVatId: vatId,
        newName: name,
        newCode: code,
        newAddress: address || "",
        newEmail: email || [],
        newPaymentDue: paymentDue ? parseInt(paymentDue) : 0,
        updateFiles: updateFiles,
      },
    })
      .then((res) => {
        cb(res.success)
        if (res.success) {
          dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
          dispatch({
            type: CompanyConst.UPDATE_COUNTERPARTY,
            companyId: companyId,
            vatId: vatId,
            name: name,
            code: code,
            id: counterpartyId,
            address: address,
            email: email || [],
            paymentDue: paymentDue,
          });
          if (updateFiles) {
            dispatch({ type: FileConst.LOAD_NEW_FILE_NAMES, files: res.files });
          }
        } else {
          dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        cb(false)
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/counterparties/edit`);
      });
  };
}

function addCounterparty(companyId, vatId, name, email, code, address, paymentDue, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/counterparties/add`, {
      method: "post",
      body: {
        companyId: companyId,
        vatId: vatId,
        name: name,
        code: code,
        address: address || "",
        email: email || [],
        paymentDue: paymentDue ? parseInt(paymentDue) : 0,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
          dispatch({
            type: CompanyConst.ADD_COUNTERPARTY,
            companyId: companyId,
            newCounterparty: res.newCounterparty
          })
          cb(res.success, res.newCounterparty.id)
        } else {
          cb(false)
          dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        cb(false)
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/counterparties/add`);
      });
  };
}

function mergeCounterparties(
  companyId,
  counterpartiesToMerge,
  name,
  code,
  vatId,
  cb
) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/counterparties/merge`, {
      method: "put",
      body: {
        companyId: companyId,
        counterpartiesToMerge: counterpartiesToMerge,
        newName: name,
        newCode: code,
        newVatId: vatId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: FileConst.LOAD_NEW_FILE_NAMES, files: res.files });

          myFetch(`/counterparties/get-company-counterparties/${companyId}`, {
            method: "get",
          })
            .then((res) => {
              dispatch({
                type: FileConst.LOADING_SCREEN,
                loadingScreen: false,
              });
              dispatch({
                type: CompanyConst.GET_COMPANY_COUNTERPARTIES,
                counterparties: res.counterparties,
              });
              cb(true);
            })
            .catch((err) => {
              dispatch({
                type: FileConst.LOADING_SCREEN,
                loadingScreen: false,
              });
              handleError(
                err,
                dispatch,
                `/counterparties/get-company-counterparties/${companyId}`
              );
              cb(true);
            });
        } else {
          dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
          dispatch(AlertActions.warning(res.msg));
          cb(false);
        }
      })
      .catch((err) => {
        cb(false);
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/counterparties/merge`);
      });
  };
}

function importCounterparties(file, companyId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/counterparties/import`, {
      method: "post",
      body: {
        file: file,
        companyId: companyId,
      },
    })
      .then((res) => {
        console.log(res)
        if (res.success) {
          dispatch(AlertActions.info('Number of new counterpartie: {n}', 5000, res.newImportedCounterparties.length))
          cb(res.newImportedCounterparties, res.notImportedCounterparties)
        }
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        myFetch(`/counterparties/get-company-counterparties/${companyId}`, {
          method: "get",
        })
          .then((res) => {
            dispatch({
              type: CompanyConst.GET_COMPANY_COUNTERPARTIES,
              counterparties: res.counterparties,
            });
          })
          .catch((err) => {
            handleError(
              err,
              dispatch,
              `/counterparties/get-company-counterparties/${companyId}`
            );
          });
      })
      .catch((err) => {
        handleError(err, dispatch, `/counterparties/import`);
      });
  };
}


function switchCounterpartiesSortingFilter(key) {
  return (dispatch) => {
    dispatch({ type: CompanyConst.SORT_COUNTERPARTIES, name: key });
  };
}

function getAllCompanyWorkers(companyId) {
  return (dispatch) => {
    myFetch(`/user-companies/get-company-members/${companyId}`, {
      methot: "get",
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.ALL_USERS_ASSOCIATED_WITH_COMPANY,
            companyUsers: res.users,
            count: res.users.length,
            usersList: res.users,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/user-companies/get-company-members/${companyId}`
        );
      });
  };
}

function switchCompanyMembersSorting(field) {
  return (dispatch) => {
    dispatch({
      type: CompanyConst.CHANGE_COMPANY_WORKERS_SORTING,
      field: field,
    });
  };
}

function getProjectWorkers(projectId) {
  return (dispatch) => {
    myFetch(`/user-projects/get-project-members/${projectId}`, {
      methot: "get",
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.PROJECT_WORKERS,
            projectWorkers: res.users,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/user-projects/get-project-members/${projectId}`
        );
      });
  };
}

function saveProjectWorkers(projectId, usersToAdd, usersToDelete) {
  return (dispatch) => {
    addProjectMembers(projectId, usersToAdd, (err, updatedCountAdd) => {
      removeProjectMembers(
        projectId,
        usersToDelete,
        (err, updatedCountDelete) => {
          myFetch(`/user-projects/get-project-members/${projectId}`, {
            methot: "get",
          })
            .then((res) => {
              dispatch({
                type: CompanyConst.PROJECT_WORKERS,
                projectWorkers: res.users,
              });
              dispatch(AlertActions.info("Users successfully updated."));
            })
            .catch((err) => {
              handleError(
                err,
                dispatch,
                `/user-projects/get-project-members/${projectId}`
              );
            });
        }
      );
    });
  };
}

async function addProjectMembers(projectId, usersToAdd, callback) {
  const promises = usersToAdd.map(async (user) => {
    return new Promise((resolve, reject) => {
      myFetch(`/user-projects/add-user`, {
        method: "post",
        body: {
          projectId: projectId,
          userId: user.id,
        },
      })
        .then((res) => {
          resolve(1);
        })
        .catch((err) => {
          console.log(err);
          resolve(0);
        });
    });
  });

  const updatedCount = await Promise.all(promises);
  var sum = 0;
  updatedCount.forEach((element) => {
    sum += element;
  });
  callback(null, sum);
}

async function removeProjectMembers(projectId, usersToDelete, callback) {
  const promises = usersToDelete.map(async (user) => {
    return new Promise((resolve, reject) => {
      usersToDelete.forEach((user) => {
        myFetch(`/user-projects/delete-access-to-bucket/${user.instanceId}`, {
          method: "put",
        })
          .then((res) => {
            resolve(1);
          })
          .catch((err) => {
            console.log(err);
            resolve(0);
          });
      });
    });
  });

  const updatedCount = await Promise.all(promises);
  var sum = 0;
  updatedCount.forEach((element) => {
    sum += element;
  });
  callback(null, sum);
}

function getUserCompanies(userId) {
  return (dispatch) => {
    myFetch(`/user-companies/get-mine`, { methot: "get" })
      .then((res) => {
        dispatch({ type: CompanyConst.USER_COMPANIES, companies: res });
      })
      .catch((err) => {
        handleError(err, dispatch, `/user-companies/get-mine`);
      });
  };
}

function setCurrentCompany(company) {
  return (dispatch) => {
    localStorage.setItem('currentCompany', company.company_id)
    dispatch({ type: CompanyConst.SET_CURRENT_COMPANY, company: company });
  };
}

function findCompanyByVatId(nip, fileId, method) {
  return dispatch => {
    myFetch(`/companies/find-by-vat-id?vatId=${nip}`, { method: 'get' }).then(res => {
      if (res.success && res.company) {
        dispatch({ type: FileConst.GET_COMPANY_BY_VATID, fileId: fileId, method: method, companyName: res.company })
      }
    }).catch(err => {
      handleError(err, dispatch, `/companies/find-by-vat-id?vatId=${nip}`)
    })
  }
}

function changeUsersRoleInProject(projectId, users, role, companyId) {
  return dispatch => {
    dispatch({ type: CompanyConst.CHANGE_USERS_ROLE_IN_PROJECT, users: users, role: role, companyId: companyId })
    myFetch(`/user-projects/change-users-role`, {
      method: 'put', body: {
        projectId: projectId,
        users: users,
        role: role
      }
    }).then(res => {
      if (res.success) {
        console.log("roles updated in db")
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      handleError(err, dispatch, `/user-projects/change-users-role`)
    })
  }
}

function removeUsersFromProject(projectId, users) {
  return dispatch => {
    dispatch({ type: CompanyConst.REMOVE_USERS_FROM_PROJECT, users: users, projectId: projectId })
    myFetch(`/user-projects/remove-users`, {
      method: 'put', body: {
        projectId: projectId,
        users: users
      }
    }).then(res => {
      if (res.success) {
        console.log("users removed in db")
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      handleError(err, dispatch, `/user-projects/remove-users`)
    })
  }
};

function inviteAndAssignMember(projects, email, companyId, cb) {
  return dispatch => {
    dispatch({ type: CompanyConst.SEND_INVITE_AND_ASSIGN_MEMBER_REQUEST })
    myFetch(`/user-projects/invite-and-assign`, {
      method: 'post', body: {
        projects: projects,
        email: email.toLowerCase()
      }
    }).then(res => {
      if (res.success) {
        dispatch({ type: CompanyConst.GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE, user: res.user, projects: projects, companyId: companyId })
      } else {
        dispatch({ type: CompanyConst.GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE, user: null, error: res.msg })
      }
      cb(res.success)
    }).catch(err => {
      console.log(err)
      cb(false)
      dispatch({ type: CompanyConst.GET_INVITE_AND_ASSIGN_MEMBER_RESPONSE, user: null, error: 'An error occured' })
    })
  }
}

function toggleAdditionalAprovalForDocs(projectId, companyId, selectedUsers, requiredAcceptanceNumber, cb) {
  return dispatch => {
    myFetch(`/projects/toggle-additional-acceptacne-over-treshold`, {
      method: 'put', body: {
        projectId: projectId,
        selectedUsers: selectedUsers,
        requiredAcceptanceNumber: requiredAcceptanceNumber
      }
    }).then(res => {
      cb(res.success)
      if (res.success) {
        myFetch(`/user-projects/my-projects`, { method: 'get' }).then(res => {
          dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: res.userProjects, groupedProjects: res.groupedProjects })
        }).catch(err => {
          handleError(err, dispatch, `/user-projects/my-projects`)
        })
        myFetch(`/projects/get-company-projects/${companyId}`, { method: 'get' })
          .then(res => {
            dispatch({ type: CompanyConst.GET_COMPANY_PROJECTS, companyProjects: res.projects })
          }).catch(err => {
            handleError(err, dispatch, `/projects/get-company-projects/${companyId}`)
          })
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/projects/toggle-additional-acceptacne-over-treshold`)
    })
  }
}

function toggleAdditionalAprovalForDocsUnderTreshold(projectId, companyId, selectedUsers, requiredAcceptanceNumber, cb) {
  return dispatch => {
    myFetch(`/projects/toggle-additional-acceptacne-under-treshold`, {
      method: 'put', body: {
        projectId: projectId,
        selectedUsers: selectedUsers,
        requiredAcceptanceNumber: requiredAcceptanceNumber
      }
    }).then(res => {
      cb(res.success)
      if (res.success) {
        myFetch(`/user-projects/my-projects`, { method: 'get' }).then(res => {
          dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: res.userProjects, groupedProjects: res.groupedProjects })
        }).catch(err => {
          handleError(err, dispatch, `/user-projects/my-projects`)
        })
        myFetch(`/projects/get-company-projects/${companyId}`, { method: 'get' })
          .then(res => {
            dispatch({ type: CompanyConst.GET_COMPANY_PROJECTS, companyProjects: res.projects })
          }).catch(err => {
            handleError(err, dispatch, `/projects/get-company-projects/${companyId}`)
          })
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/projects/toggle-additional-acceptacne-under-treshold`)
    })
  }
}

function changeAdditionalDocsAcceptanceTresholds(projectId, companyId, plnTreshold, eurTreshold, cb) {
  return dispatch => {
    myFetch(`/projects/change-additional-docs-acceptacne-tresholds`, {
      method: 'put', body: {
        projectId: projectId,
        plnTreshold: plnTreshold,
        eurTreshold: eurTreshold,
      }
    }).then(res => {
      cb(res.success)
      if (res.success) {
        myFetch(`/user-projects/my-projects`, { method: 'get' }).then(res => {
          dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: res.userProjects, groupedProjects: res.groupedProjects })
        }).catch(err => {
          handleError(err, dispatch, `/user-projects/my-projects`)
        })
        myFetch(`/projects/get-company-projects/${companyId}`, { method: 'get' })
          .then(res => {
            dispatch({ type: CompanyConst.GET_COMPANY_PROJECTS, companyProjects: res.projects })
          }).catch(err => {
            handleError(err, dispatch, `/projects/get-company-projects/${companyId}`)
          })
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/projects/change-additional-docs-acceptacne-tresholds`)
    })
  }
}

function saveUsersForAdditionalAcceptance(projectId, companyId, selectedUsers, additionalAcceptanceType, requiredAcceptanceNumber, cb) {
  return dispatch => {
    myFetch(`/projects/save-new-users-for-additional-acceptance`, {
      method: 'put', body: {
        projectId: projectId,
        selectedUsers: selectedUsers,
        type: additionalAcceptanceType,
        requiredAcceptanceNumber: requiredAcceptanceNumber
      }
    }).then(res => {
      cb(res.success)
      if (res.success) {
        myFetch(`/user-projects/my-projects`, { method: 'get' }).then(res => {
          dispatch({ type: UserConst.GET_USER_PROJECTS, userProjects: res.userProjects, groupedProjects: res.groupedProjects })
        }).catch(err => {
          handleError(err, dispatch, `/user-projects/my-projects`)
        })
        myFetch(`/projects/get-company-projects/${companyId}`, { method: 'get' })
          .then(res => {
            dispatch({ type: CompanyConst.GET_COMPANY_PROJECTS, companyProjects: res.projects })
          }).catch(err => {
            handleError(err, dispatch, `/projects/get-company-projects/${companyId}`)
          })
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/projects/change-additional-docs-acceptacne-tresholds`)
    })
  }
}

function getCurrentProjectAcceptanceConfiguration(projectId, cb) {
  return dispatch => {
    myFetch(`/projects/get-additional-acceptance-configuration/${projectId}`, {
      method: 'get'
    }).then(res => {
      if (res.success) {
        cb(res.configuration)
      } else {
        cb(null)
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(null)
      handleError(err, dispatch, `/projects/get-additional-acceptance-configuration/${projectId}`)
    })
  }
}

function setCurrentProjectAcceptanceConfiguration(projectId, hasAdditionalAcceptanceOn, acceptanceTresholds, userAcceptanceTresholds, cb) {
  return dispatch => {
    myFetch(`/projects/set-additional-acceptance-configuration`, {
      method: 'put', body: {
        projectId,
        hasAdditionalAcceptanceOn,
        acceptanceTresholds,
        userAcceptanceTresholds
      }
    }).then(res => {
      cb(res.success)
      if (res.msg) {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      cb(false)
      handleError(err, dispatch, `/projects/set-additional-acceptance-configuration`)
    })
  }
}

function resetInviteNewUserMessage() {
  return (dispatch) => {
    dispatch({ type: CompanyConst.RESET_INVITE_AND_ASSIGN_MEMBER_MESSAGE });
  };
}

function deleteCompanyMember(memberId, companyId) {
  return (dispatch) => {
    myFetch(`/user-companies/delete-user`, {
      method: "post",
      body: {
        memberId: memberId,
        companyId: companyId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.REMOVE_MEMBER,
            memberId: memberId,
            companyId: companyId,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/user-companies/delete-user`);
      });
  };
}

function changeUserRole(memberId, role, companyId) {
  return dispatch => {
    myFetch(`/user-companies/change-member-role`, {
      method: 'put', body: {
        memberId: memberId,
        role: role,
        companyId: companyId,
      }
    }).then(res => {
      if (res.success) {
        dispatch({ type: CompanyConst.CHANGE_MEMBER_ROLE, memberId: memberId, newRole: role, companyId: companyId })
      } else {
        dispatch(AlertActions.warning(res.msg))
      }
    }).catch(err => {
      handleError(err, dispatch, `/user-companies/change-member-role`)
    })
  }
}

function chackeIfProjectCodeIsUnique(code, cb) {
  return (dispatch) => {
    myFetch(`/projects/check-code/${code}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          cb(res.unique)
        } else {
          cb(false)
          console.log(res.msg);
        }
      })
      .catch((err) => {
        cb(false)
        handleError(err, dispatch, `/projects/check-code/${code}`);
      });
  };
}

function loadNewCompanyTags(companyId, newTags) {
  return (dispatch) => {
    dispatch({
      type: CompanyConst.LOAD_NEW_TAGS,
      companyId: companyId,
      newTags: newTags,
    });
    dispatch({
      type: UserConst.LOAD_NEW_TAGS_FOR_COMPANY,
      companyId: companyId,
      newTags: newTags,
    });
  };
}

function registerSubscriptionPayment(transaction) {
  console.log(transaction);
  return (dispatch) => {
    myFetch(`/subscription-fees/register`, {
      method: "post",
      body: {
        transaction: transaction,
      },
    })
      .then((res) => {
        if (res.success) {
          console.log(res);
        } else {
          console.log(res.msg);
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/subscription-fees/register`);
      });
  };
}

function updateCompanySubscription(companyId, subscriptionExpireDate, role) {
  return (dispatch) => {
    dispatch({ type: CompanyConst.UPDATE_SUBSCRIPTION, companyId: companyId, subscriptionExpireDate: subscriptionExpireDate });
    dispatch({ type: UserConst.UPDATE_COMPANY_SUBSCRIPTION, companyId: companyId, subscriptionExpireDate: subscriptionExpireDate });
    if (role === "ADMIN") {
      myFetch(`/subscription-fees/get-company-transactions/${companyId}`, {
        method: "get",
      })
        .then((res) => {
          if (res.success) {
            dispatch({
              type: CompanyConst.GET_SUBSCRIPTION_TRANSACTIONS,
              companyId: companyId,
              transactions: res.transactions,
            });
          } else {
            console.log(res.msg);
          }
        })
        .catch((err) => {
          handleError(
            err,
            dispatch,
            `/subscription-fees/get-company-transactions/${companyId}`
          );
        });
    }
  };
}

function cancelSubscription(companyId) {
  return (dispatch) => {
    myFetch(`/companies/cancel-subscription`, {
      method: "put",
      body: {
        companyId: companyId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch(AlertActions.info("Your subscription has beed canceled"));
          dispatch({
            type: CompanyConst.CANCE_SUBSCRIPTION,
            companyId: companyId,
          });
        } else {
          console.log(res.msg);
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/cancel-subscription`);
      });
  };
}

function changeCompanySubscriptionType(companyId, newPlan, cb) {
  return (dispatch) => {
    myFetch(`/companies/change-subscription-type`, {
      method: "put",
      body: {
        companyId: companyId,
        newSubType: newPlan,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch(AlertActions.info("Your subscription has beed canceled"));
          dispatch({
            type: CompanyConst.UPDATE_SUBSCRIPTION_TYPE,
            companyId: companyId,
            newPlan: newPlan,
          });
        } else {
          console.log(res.msg);
        }
        cb(res.success);
      })
      .catch((err) => {
        cb(false);
        handleError(err, dispatch, `/companies/change-subscription-type`);
      });
  };
}

function getCompanysubscriptionTransactions(companyId) {
  return (dispatch) => {
    dispatch({ type: CompanyConst.GET_SUBSCRIPTION_TRANSACTIONS_SEND });
    myFetch(`/subscription-fees/get-company-transactions/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.GET_SUBSCRIPTION_TRANSACTIONS,
            companyId: companyId,
            transactions: res.transactions,
          });
        } else {
          console.log(res.msg);
        }
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/subscription-fees/get-company-transactions/${companyId}`
        );
      });
  };
}

function getMyLatestSubsctiptionPayment(companyId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/subscription-fees/get-my-latest-transactions/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          cb(res.transaction);
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb(null);
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(null);
        handleError(
          err,
          dispatch,
          `/subscription-fees/get-my-latest-transactions/${companyId}`
        );
      });
  };
}

function switchTransactionsSortingFilter(field) {
  return (dispatch) => {
    dispatch({
      type: CompanyConst.SORT_SUBSCRIPTION_TRANSACTIOS,
      field: field,
    });
  };
}

function makeEspagoPayment(paymentOptions, companyId, additionalInfo, cb) {
  return (dispatch) => {
    myFetch(`/subscription-fees/make-espago-payment`, {
      method: "post",
      body: {
        paymentOptions: JSON.stringify(paymentOptions),
        companyId: companyId,
        additionalInfo: additionalInfo,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.success) {
          //callback(null, {success: true, newTransaction: null, redirect: false, redirectUrl: ''})
          if (res.redirect) {
            var a = document.createElement("a");
            a.href = res.redirectUrl;
            a.target = "_blank";
            a.click();
            cb(false, "new", {});
          } else if (res.rejected) {
            dispatch(
              AlertActions.info(
                "Payment was rejected, your subscription has not been renewed"
              )
            );
            cb(true, "rejected", {
              rejectReason: res.rejectReason,
              responseCode: res.responseCode,
            });
          } else {
            dispatch(
              AlertActions.info(
                "Successful payment, your subscription has been renewed"
              )
            );
            cb(true, "executed", null, res.newTransaction.transaction_id);
          }

          dispatch({
            type: CompanyConst.LOAD_NEW_TRANSACTION,
            transaction: res.newTransaction,
          });
        } else {
          console.log(res.msg);
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/cancel-subscription`);
      });
  };
}

function manualPaymentClick(companyId, cb) {
  return (dispatch) => {
    myFetch(`/subscription-fees/manual-recurring-payment`, {
      method: "post",
      body: {
        companyId: companyId
      },
    })
      .then((res) => {
        if (res.success) {
          if (res.rejected) {
            dispatch(AlertActions.info("Payment was rejected, your subscription has not been renewed. You will receive and email with more details sortly", 10000));
            cb(true, "rejected", { rejectReason: res.rejectReason, responseCode: res.responseCode });
          } else {
            dispatch(AlertActions.info("Successful payment, your subscription has been renewed"));
            cb(true, "executed");
            // dispatch({ type: CompanyConst.UPDATE_SUBSCRIPTION, companyId: companyId, subscriptionExpireDate: subscriptionExpireDate });
            // dispatch({ type: UserConst.UPDATE_COMPANY_SUBSCRIPTION, companyId: companyId, subscriptionExpireDate: subscriptionExpireDate });
          }

          dispatch({ type: CompanyConst.LOAD_NEW_TRANSACTION, transaction: res.newTransaction });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/subscription-fees/manual-recurring-payment`);
      });
  };
}

function getDocumentNumberForNewInvoice(month, companyId, documentType, cb) {
  return (dispatch) => {
    myFetch(
      `/sale-invoices/get-next-invoice-number/${month}/${companyId}/${documentType}`,
      { method: "get" }
    )
      .then((res) => {
        if (res.success) {
          cb(res.nextNumbers);
        } else {
          //brak zdefiniowanych serii numeracji, pokazac popup do zdefiniowania
          cb(false, true);
        }
      })
      .catch((err) => {
        cb(null, null);
      });
  };
}

function CheckCorrectInvoiceNumberforCompany(month, invoiceNumber, companyId, docType, numberingSeriesId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      myFetch(
        `/sale-invoices/check-correct-invoice-nr/`,
        {
          method: "post",
          body: { month, invoiceNumber, companyId, docType, numberingSeriesId }
        }
      )
        .then((res) => {
          if (res.success) {
            if (res.nextNumbers) {
              dispatch({
                type: CompanyConst.IS_OPEN_INVOICE_NUMBER_POPUP,
                isNewInvoiceNumberPopupOpen: true,
                newInvoiceNumber: res.nextNumbers
              });
              resolve(res.nextNumbers);
            } else if (res.foundNumberingGap && res.invoiceNumberToFillGap) {
              dispatch({
                type: CompanyConst.IS_OPEN_INVOICE_NUMBER_POPUP,
                isNewInvoiceNumberPopupOpen: true,
                newInvoiceNumber: res.invoiceNumberToFillGap,
                foundNumberingGap: true
              });
            } else {
              resolve()
            }
          } else {
            reject(new Error("Błąd sprawdzania numeru faktury"));
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
          handleError(err, dispatch, `/sale-invoices/check-correct-invoice-nr/`);
        });
    });
  };
}

function closePopupAndDeleteInvoiceNumber() {
  return (dispatch) => {
    dispatch({ type: CompanyConst.IS_OPEN_INVOICE_NUMBER_POPUP, isNewInvoiceNumberPopupOpen: false, newInvoiceNumber: '' });
  }
}

function getSavedDocItems(companyId, cb) {
  return (dispatch) => {
    myFetch(`/doc-items-dictionary/get-unique-doc-items/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          cb(res.uniqueDocItems);
        } else {
          cb(null);
        }
      })
      .catch((err) => {
        cb(null);
      });
  };
}

function getSavedRecurringInvoice(companyId, cb) {
  return (dispatch) => {
    myFetch(`/recurring_sale_invoices/get-mine/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          cb(res.savedRecurringInvoice);
        } else {
          cb(null);
        }
      })
      .catch((err) => {
        cb(null);
      });
  };
}

function generateExamplePdf(companyId, recurringInvoiceData, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/recurring_sale_invoices/generate-example-pdf`, {
      method: "post", body: {
        companyId,
        recurringInvoiceData
      }
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(res.success, res.examplePdf)
        if (!res.success) {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false);
        handleError(err, dispatch, `/recurring_sale_invoices/generate-example-pdf`);
      })
  }
}

function createRecurringInvoice(companyId, newRecurringInvoiceData, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/recurring_sale_invoices/create`, {
      method: "post", body: {
        companyId,
        newRecurringInvoiceData
      }
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(res.success, res.examplePdf, res.newRecurringInvoiceId)
        if (!res.success) {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false);
        handleError(err, dispatch, `/recurring_sale_invoices/create`);
      })
  }
}

function saveChangesToRecurringInvoice(companyId, editedRecurringInvoieId, newRecurringInvoiceData, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/recurring_sale_invoices/edit`, {
      method: "put", body: {
        companyId,
        editedRecurringInvoieId,
        newRecurringInvoiceData
      }
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(res.success, res.examplePdf, res.recurringInvoiceId)
        if (!res.success) {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false);
        handleError(err, dispatch, `/recurring_sale_invoices/edit`);
      })
  }
}

function deleteRecurringInvoice(companyId, editedRecurringInvoieId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/recurring_sale_invoices/delete`, {
      method: "delete", body: {
        companyId,
        editedRecurringInvoieId
      }
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(res.success)
        if (!res.success) {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false);
        handleError(err, dispatch, `/recurring_sale_invoices/delete`);
      })
  }
}

function saveNewInvoiceItem(companyId, name, pkwiu, gtu, amount, unit, netPrice, vatRate, cb) {
  return (dispatch) => {
    myFetch(`/doc-items-dictionary/add-new`, {
      method: "post",
      body: {
        companyId: companyId,
        name: name,
        pkwiu: pkwiu,
        gtu: gtu,
        amount: amount,
        unit: unit,
        netPrice: netPrice,
        vatRate: vatRate
      }
    }).then((res) => {
      if (res.success) {
        cb(res.newItem)
      } else {
        cb(null)
        dispatch(AlertActions.warning(res.msg));
      }
    }).catch((err) => {
      cb(null);
      handleError(err, dispatch, `/doc-items-dictionary/add-new`);
    });
  };
}

function editInvoiceItem(companyId, itemId, name, pkwiu, gtu, amount, unit, netPrice, vatRate, cb) {
  return (dispatch) => {
    myFetch(`/doc-items-dictionary/edit`, {
      method: "put",
      body: {
        companyId: companyId,
        itemId: itemId,
        name: name,
        pkwiu: pkwiu,
        gtu: gtu,
        amount: amount,
        unit: unit,
        netPrice: netPrice,
        vatRate: vatRate
      }
    }).then((res) => {
      if (res.success) {
        cb(res.newItem)
      } else {
        cb(null)
        dispatch(AlertActions.warning(res.msg));
      }
    }).catch((err) => {
      cb(null);
      handleError(err, dispatch, `/doc-items-dictionary/edit`);
    });
  };
}

function deleteDocItem(companyId, itemId, cb) {
  return (dispatch) => {
    myFetch(`/doc-items-dictionary/delete`, {
      method: "delete",
      body: {
        companyId: companyId,
        itemId: itemId
      }
    }).then((res) => {
      if (res.success) {
        cb(true)
      } else {
        cb(false)
        dispatch(AlertActions.warning(res.msg));
      }
    }).catch((err) => {
      cb(false);
      handleError(err, dispatch, `/doc-items-dictionary/delete`);
    });
  };
}

function getPrieviosGeneratedInvoices(companyId) {
  return (dispatch) => {
    dispatch({ type: FileConst.GET_COMPANY_GENERATED_INVOICES, companyId: companyId, invoices: [] });
    myFetch(`/sale-invoices/get-mine/${companyId}/0`, { method: "get" })
      .then((res) => {
        if (res.success) {
          dispatch({ type: FileConst.GET_COMPANY_GENERATED_INVOICES, companyId: companyId, invoices: res.invoices });
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/sale-invoices/get-mine/${companyId}/0`
        );
      });
  };
}

function downloadGeneratedInvoice(id, companyId, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/download-file/${id}/${companyId}`, {
      method: "get",
    })
      .then((res) => {
        if (res.success) {
          cb(res.name, res.data);
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        cb();
        handleError(
          err,
          dispatch,
          `/sale-invoices/download-file/${id}/${companyId}`
        );
      });
  };
}

function deleteGeneratedInvoice(id, companyId, filePath, fileId, projectId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/sale-invoices/permanent-delete-invoice`, {
      method: "delete",
      body: {
        invoiceId: id,
        companyId: companyId
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(res.success);
        if (!res.success) {
          dispatch(AlertActions.warning(res.msg));
        } else {
          dispatch({ type: FileConst.DELETE_GENERATED_INVOICE, companyId: companyId, invoiceId: id, filePath: filePath });
          dispatch({ type: FileConst.DELETE_FILE_PERMANENT, id: fileId, projectId: projectId })
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false);
        handleError(err, dispatch, `/sale-invoices/permanent-delete-invoice`);
      });
  };
}

function acceptGeneratedSaleInvoice(id, companyId, userId) {
  return (dispatch) => {
    dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, accepted: true, userId: userId });
    myFetch(`/sale-invoices/accept-generated-invoice`, {
      method: "put",
      body: {
        invoiceId: id,
        companyId: companyId,
      },
    })
      .then((res) => {
        if (!res.success) {
          dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, accepted: false });
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, accepted: false });
        handleError(err, dispatch, `/sale-invoices/permanent-delete-invoice`);
      });
  };
}

function unlockSaleInvoice(id, companyId, userId) {
  return (dispatch) => {
    dispatch({ type: FileConst.UNLOCK_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, userId: userId });
    myFetch(`/sale-invoices/unlock-generated-invoice`, {
      method: "put",
      body: {
        invoiceId: id,
        companyId: companyId,
      },
    })
      .then((res) => {
        if (!res.success) {
          dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, accepted: true, userId: userId });
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: id, companyId: companyId, accepted: true, userId: userId });
        handleError(err, dispatch, `/sale-invoices/unlock-generated-invoice`);
      });
  };
}

function handleLoadScreen(change) {
  return (dispatch) => {
    if (change) dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    if (!change) dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
    
  }
}

function generateInvoice(data, companyId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/sale-invoices/generate-invoice`, {
      method: "post",
      body: {
        data: JSON.stringify(data),
        companyId: companyId,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          cb(res.success, res.data, res.fileName, res.id, res.newInvoice);
          dispatch({ type: FileConst.GENERATED_NEW_INVOICE, companyId: companyId, newInvoice: res.newInvoice, invoiceId: res.id });
        } else {
          cb(false, null, null);
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/sale-invoices/generate-invoice`);
        cb(false);
      });
  };
}

function getNewGeneratedInvoice(companyId, invoiceId) {
  return (dispatch) => {
    myFetch(`/sale-invoices/get-mine/${companyId}/${invoiceId}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          if (res.newInvoice) {
            dispatch({ type: FileConst.GENERATED_NEW_INVOICE, companyId: companyId, newInvoice: res.newInvoice, invoiceId: invoiceId });
          }
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/sale-invoices/get-mine/${companyId}/${invoiceId}`
        );
      });
  };
}

function editInvoice(data, companyId, invoiceId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/sale-invoices/edit-generated-invoice`, {
      method: "put",
      body: {
        data: JSON.stringify(data),
        companyId: companyId,
        invoiceId: invoiceId,
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          cb(res.success, res.data, res.fileName, res.id);
          dispatch({ type: FileConst.DELETE_GENERATED_INVOICE, companyId: companyId, invoiceId: invoiceId, filePath: res.oldFilePath })
          dispatch(getNewGeneratedInvoice(companyId, invoiceId))
        } else {
          cb(false, null, null);
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        handleError(err, dispatch, `/sale-invoices/edit-generated-invoice`);
        cb(false);
      });
  };
}

function sendInvoiceByEmail(invoiceId, email, customSubject, customAttachments, companyId, userId, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/sale-invoices/send-via-email`, {
      method: "post",
      body: {
        invoiceId: invoiceId,
        email: email.toLowerCase(),
        customSubject: customSubject,
        customAttachments: customAttachments,
      },
    }).then(res => {
      if (res.success) {
        if (res.info) dispatch(AlertActions.info(res.info), 10000)
        dispatch({ type: FileConst.ACCEPT_GENERATED_SALE_INVOICE, invoiceId: invoiceId, companyId: companyId, accept: true, userId: userId, email: res.sentToField });
      } else {
        dispatch(AlertActions.warning(res.msg), 10000)
      }
      cb(true)
      dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
    }).catch((err) => {
      cb(false)
      handleError(err, dispatch, `/sale-invoices/send-via-email`);
    });
  };
}

function importInvoice(file, companyId, currencyRatesOption, currencyDate, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch(`/sale-invoices/import-invoice`, {
      method: "post",
      body: {
        file: file,
        companyId: companyId,
        currencyRatesOption: currencyRatesOption,
        currencyDate: currencyDate
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });

        if (res.success) {
          cb(res.success, res.msg, null, res.count);
        } else if (!res.success && res.err) {
          cb(res.success, res.err);
        } else if (!res.success && res.msg) {
          cb(res.success, "Import invoices failed!", JSON.parse(res.msg));
        }
      })
      .catch((err) => {
        handleError(err, dispatch, `/sale-invoices/import-invoice`);
      });
  };
}

function getAllCorrectionsToInvoice(invoiceId, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/get-all-corrections/${invoiceId}`, {
      method: "get",
    })
      .then((res) => {
        cb(res.success, res.correctionsList, res.otherSaleInvoices)
      }).catch((err) => {
        cb(false)
      });
  }
}

function getInvoiceImportExcelFile(invoiceId, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/get-import-excel-file/${invoiceId}`, {
      method: "get",
    })
      .then((res) => {
        cb()
        if (res.success) {
          var dLink = document.createElement('a')
          dLink.download = res.name
          dLink.href = res.data
          dLink.click()
        } else {
          dispatch(AlertActions.warning(res.msg))
        }
      })
      .catch((err) => {
        cb()
        handleError(err, dispatch, `/sale-invoices/get-import-excel-file`);
      });
  }
}

function sendInvoiceToKSeF(invoiceId, companyId, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/send-to-ksef`, {
      method: "post", body: {
        invoiceId: invoiceId,
        companyId: companyId
      }
    })
      .then((res) => {
        cb()
        dispatch({ type: FileConst.SEND_SALE_INVOICE_TO_KSEF, invoiceId: invoiceId, companyId: companyId, success: res.success });
      })
      .catch((err) => {
        cb()
        handleError(err, dispatch, `/sale-invoices/send-to-ksef`);
      });
  }
}

function generateJPK_FA(companyId, startDate, endDate, departmentId, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/generate-jpk_fa`, {
      method: "post",
      body: {
        companyId: companyId,
        startDate: startDate,
        endDate: endDate,
        departmentId: departmentId.toString()
      }
    })
      .then((res) => {
        cb(res.success)
        if (res.success) {
          var dLink = document.createElement('a')
          dLink.download = res.name
          dLink.href = res.data
          dLink.click()
        } else {
          dispatch(AlertActions.warning(res.msg))
        }
      })
      .catch((err) => {
        cb(false)
        handleError(err, dispatch, `/sale-invoices/generate-jpk_fa`);
      });
  }
}

function getCompanyWaproNetAccounts(companyId, cb) {
  return (dispatch) => {
    myFetch(`/companies/get-wapro-accounts/${companyId}`, { method: "get" })
      .then((res) => {
        cb(res.success, res.accounts);
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/companies/get-wapro-accounts/${companyId}`
        );
        cb(false);
      });
  };
}

function getCompanySymfoniaFile(companyId, cb) {
  return (dispatch) => {
    myFetch(`/companies/get-symfonia-lists/${companyId}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          cb(res)
        }
        else cb(false)
      })
      .catch((err) => {
        handleError(
          err,
          dispatch,
          `/companies/get-symfonia-lists/${companyId}`
        );
        cb(false);
      });
  };
}

function getOriginalDocNumbersForCounterparty(companyId, counterpartyVatId, cb) {
  return (dispatch) => {
    myFetch(`/companies/get-counterpart-docs`, {
      method: "post",
      body: {
        companyId: companyId,
        counterpartyVatId: counterpartyVatId,
      },
    })
      .then((res) => {
        cb(res.success, res.docs);
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/get-counterpart-docs`);
        cb(false);
      });
  };
}

function getAllDocsFromCounterparty(companyId, counterpartyVatId, cb) {
  return (dispatch) => {
    myFetch(`/companies/get-all-counterpart-docs`, {
      method: "post",
      body: {
        companyId: companyId,
        counterpartyVatId: counterpartyVatId,
      },
    })
      .then((res) => {
        cb(res.success, res.docs || [], res.agreements || []);
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/get-all-counterpart-docs`);
        cb(false);
      });
  };
}

function getProjectAgreements(projectId, cb) {
  return (dispatch) => {
    myFetch(`/agreements/project-agreements`, {
      method: "post",
      body: {
        projectId: projectId,
      },
    })
      .then((res) => {
        cb(res.success, res.agreements);
      })
      .catch((err) => {
        handleError(err, dispatch, `/companies/agreements/project-agreements`);
        cb(false);
      });
  };
}

function handleError(error, dispatch, path) {
  console.log(error)
  if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    dispatch({ type: UserConst.LOGGED_FALSE })
    dispatch({ type: UserConst.LOGOUT })
    dispatch({ type: EventConst.LOGOUT })
    dispatch({ type: CompanyConst.LOGOUT })
    dispatch({ type: ReportsConst.LOGOUT })
    dispatch({ type: FileConst.LOGOUT })
    dispatch(AlertActions.info("You have been logged out.", 5000))
  } else {
    dispatch(AlertActions.setError(error, path));
    dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
  }
}
function changeCompanyLogo(logo, companyId, cb) {
  return (dispatch) => {
    myFetch("/companies/update-logo", {
      method: "put",
      body: {
        companyId: companyId,
        logo: logo,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
          cb(res.company.logo_url)
        } else {
          dispatch(AlertActions.warning(res.msg));
          cb()
        }
      })
      .catch((err) => {
        cb()
        handleError(err, dispatch, "/companies/update-logo");
      });
  };
}

function deleteCompanyLogo(companyId, cb) {
  return (dispatch) => {
    myFetch("/companies/delete-logo", {
      method: "put",
      body: {
        companyId: companyId,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({
            type: CompanyConst.UPDATE_USER_COMPANY_INFO,
            company: res.company,
          });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
        cb(res.success)
      })
      .catch((err) => {
        cb(false)
        handleError(err, dispatch, "/companies/delete-logo");
      });
  };
}

function registerNextCompany(vatId, companyName, companyCode, binderName, binderCode, cb) {
  return (dispatch) => {
    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
    myFetch("/companies/register-next-company", {
      method: "post",
      body: {
        vatId: vatId,
        companyName: companyName,
        companyCode: companyCode,
        binderName: binderName,
        binderCode: binderCode
      },
    })
      .then((res) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        if (res.success) {
          cb(true, res.companyId, res.projectId)
          dispatch(AlertActions.success("Your new company has been registered"))
        } else {
          cb(false)
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
        cb(false)
        handleError(err, dispatch, "/companies/register-next-company");
      });
  };
}

function generateDuplicate(id, cb) {
  return (dispatch) => {
    myFetch(`/sale-invoices/generate-duplicate`, {
      method: "post",
      body: {
        invoiceId: id
      },
    })
      .then((res) => {
        cb()
        if (res.success) {
          var a = document.createElement('a');
          a.href = res.data;
          a.download = res.fileName;
          a.click();
        } else {
          dispatch(AlertActions.danger(res.message));
        }
      })
      .catch((err) => {
        cb()
        handleError(err, dispatch, `/sale-invoices/generate-duplicate`);
      });
  };
}
