import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';

import DropTarget from './DropTarget'
import DragLayer from './DragLayer'

import defaultTableColumns from '../../helpers/defaultTableColumns.json'
import { UserActions } from '../../actions/UserActions'
import { AlertActions } from '../../actions/AlertActions'

import '../../css/TableColumnsCustomizer.css'

const ALL_TABLE_COLUMNS = [
    {
        title: "Select all ",
        dataKey: "all",
        width: 75,
        resizable: false,
        blocked: true
    },
    {
        title: "Document icon",
        dataKey: "fileIcon",
        width: 30,
        resizable: false,
        blocked: true
    },
    {
        title: "Counterparty",
        dataKey: "counterparty",
        width: 210,
        resizable: true,
        canGrow: true,
    },
    {
        title: "Document number",
        dataKey: "documentNumber",
        width: 160,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Import Date",
        dataKey: "importDate",
        width: 75,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Issue Date",
        dataKey: "issueDate",
        width: 75,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Due Date",
        dataKey: "dueDate",
        width: 75,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Net",
        dataKey: "nettoNumber",
        width: 100,
        canGrow: true,
        resizable: true,
    },
    {
        title: "VAT",
        dataKey: "taxNumber",
        width: 100,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Gross",
        dataKey: "grossNumber",
        width: 100,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Amount to pay",
        dataKey: "priceNumber",
        width: 100,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Currency",
        dataKey: "currency",
        width: 65,
        resizable: true,
    },
    {
        title: "Currency rate",
        dataKey: "currencyRate",
        width: 100,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Recipient's account",
        dataKey: "recipientsAccount",
        width: 220,
        canGrow: true,
        resizable: true,
    },
    {
        title: "VAT taxpayer status",
        dataKey: "status",
        width: 145,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Comment",
        dataKey: "comment",
        width: 130,
        canGrow: true,
        resizable: true,
    },
    {
        title: "Tags",
        dataKey: "tags",
        width: 130,
        canGrow: true,
        resizable: true,
    },
    {
        title: "In report",
        dataKey: "alreadyInReport",
        width: 60,
        resizable: true,
    },
    {
        title: "Split Payment",
        dataKey: "splitPayment",
        width: 70,
        resizable: true,
    },
    {
        title: "Paid?",
        dataKey: "alreadyPaid",
        width: 80,
        resizable: true,
    },
    {
        title: "Payment date",
        dataKey: "paymentDate",
        width: 75,
        resizable: true,
    },
    {
        title: "Accounted?",
        dataKey: "accounted",
        width: 85,
        resizable: true,
    },
    {
        title: "Conf?",
        dataKey: "emailToSendPaymentConfirmation",
        width: 50,
        resizable: true,
    },
    {
        title: "Uploaded by",
        dataKey: "uploadedBy",
        width: 90,
        resizable: true,
    },
    {
        title: "Accepted by",
        dataKey: "acceptedBy",
        width: 90,
        resizable: true,
    },
    {
        title: "Paid by",
        dataKey: "paidBy",
        width: 90,
        resizable: true,
    }
]

export class TableColumnsCustomizer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cursorWait: false,
            availableColumns: [],
            selectedColumns: [],
            cardWidth: 0,
            cardHeight: 0,
            fixedColumnsCount: 0
        }

        this.cardRef = React.createRef()
    }

    componentDidMount() {
        const { user } = this.props

        var selectedColumns = []
        if (user.custom_table_columns) {
            selectedColumns = JSON.parse(user.custom_table_columns)
        } else {
            selectedColumns = defaultTableColumns
        }

        var availableColumns = []
        ALL_TABLE_COLUMNS.forEach(column => {
            if (!selectedColumns.find(c => c.dataKey === column.dataKey)) availableColumns.push(column)
        })

        var fixedColumnsCount = 0;
        selectedColumns.forEach(c => {
            if (c.fixed) fixedColumnsCount++
        })

        this.setState({
            availableColumns: availableColumns,
            selectedColumns: selectedColumns,
            fixedColumnsCount: fixedColumnsCount
        })

        if (this.cardRef && this.cardRef.current) {
            const { clientWidth: width, clientHeight: height, } = this.cardRef.current
            this.setState({
                cardWidth: width,
                cardHeight: height,
            })
        }
    }

    componentDidUpdate() {
        const { cardHeight } = this.state
        const { clientHeight: height } = this.cardRef.current

        if (cardHeight !== height) {
            this.setState({
                cardHeight: height
            })
        }
    }

    handleDrop = (item, dromTargetName) => {
        console.log(item, dromTargetName)
        const { availableColumns, selectedColumns } = this.state
        if (dromTargetName === 'available') {
            //remove
            var currentAvailable = [].concat(availableColumns, [{ ...item }])
            var currentSelected = selectedColumns.filter(column => column.dataKey !== item.dataKey)

            currentSelected = currentSelected.filter(i => !i.isPseudo)
            this.setState({
                availableColumns: currentAvailable,
                selectedColumns: currentSelected
            })
        } else if (dromTargetName === 'selected') {
            //add
            var currentAvailable = availableColumns.filter(column => column.dataKey !== item.dataKey)
            var currentSelected = [...selectedColumns]

            if (currentSelected.find(column => column.dataKey === item.dataKey)) {
                currentSelected.forEach(field => {
                    if (field.isPseudo) delete field.isPseudo
                })
            } else {
                currentSelected.push(item)
            }

            currentSelected = currentSelected.filter(i => !i.isPseudo)

            this.setState({
                availableColumns: currentAvailable,
                selectedColumns: currentSelected
            })
        } else {
            //sort
            var currentSelected = [...selectedColumns]
            currentSelected = selectedColumns.filter(i => !i.isPseudo)
            this.setState({
                selectedColumns: currentSelected
            })
        }
    }

    sortSelected = (dragIndex, hoverIndex) => {
        const { selectedColumns } = this.state
        const dragItem = selectedColumns[dragIndex]

        var tempSelected = selectedColumns
        tempSelected.splice(dragIndex, 1)
        tempSelected.splice(hoverIndex, 0, dragItem)

        this.setState({
            selectedColumns: tempSelected
        });
    }

    addAndSort = (item, hoverIndex) => {
        const { selectedColumns } = this.state
        var dragItem = { ...item }
        dragItem.isPseudo = true

        var tempSelected = selectedColumns.filter(i => !i.isPseudo)
        tempSelected.splice(hoverIndex, 0, dragItem)

        this.setState({
            selectedColumns: tempSelected
        });
    }

    removeAllPseudoElements = () => {
        const { selectedColumns } = this.state
        var currentSelected = [...selectedColumns]
        currentSelected = selectedColumns.filter(i => !i.isPseudo)
        this.setState({
            selectedColumns: currentSelected
        })
    }

    getOriginalWidthsForColumn = dataKey => {
        switch (dataKey) {
            case 'all': return 75
            case 'fileIcon': return 30
            case 'counterparty': return 210
            case 'documentNumber': return 100
            case 'importDate': return 75
            case 'issueDate': return 75
            case 'dueDate': return 75
            case 'nettoNumber': return 100
            case 'taxNumber': return 100
            case 'grossNumber': return 100
            case 'priceNumber': return 100
            case 'currency': return 65
            case 'currencyRate': return 100
            case 'recipientsAccount': return 220
            case 'status': return 145
            case 'comment': return 130
            case 'tags': return 130
            case 'priority': return 80
            case 'alreadyInReport': return 60
            case 'splitPayment': return 70
            case 'alreadyPaid': return 80
            case 'paymentDate': return 75
            case 'uploadedBy': return 90
            case 'acceptedBy': return 90
            case 'paidBy': return 90
            case 'accounted': return 85
            case 'emailToSendPaymentConfirmation': return 50
        }
    }

    save = () => {
        const { cursorWait, selectedColumns, fixedColumnsCount } = this.state
        if (!cursorWait) {
            if (selectedColumns.length === 0) {
                this.props.alertWarn(this.context.t("You must select at least one column"))
            } else {
                this.setState({
                    cursorWait: true
                })

                selectedColumns.forEach((column, index) => {
                    column.width = this.getOriginalWidthsForColumn(column.dataKey)
                    if (index < fixedColumnsCount) column.fixed = true
                    else column.fixed = false
                })

                this.props.saveCustomTableColumns(JSON.stringify(selectedColumns), success => {
                    if (success) this.props.close()

                    this.setState({
                        cursorWait: false
                    })
                })
            }
        }
    }

    changeFixedColumnsCountCount = (type) => {
        const { fixedColumnsCount } = this.state

        var newFixedColumnsCount = fixedColumnsCount
        if (type === 'PLUS') newFixedColumnsCount++
        if (type === 'MINUS') newFixedColumnsCount--

        if (newFixedColumnsCount < 0) newFixedColumnsCount = 0
        if (newFixedColumnsCount > 7) newFixedColumnsCount = 7

        this.setState({
            fixedColumnsCount: newFixedColumnsCount
        })
    }

    render() {
        const { cursorWait, availableColumns, selectedColumns, cardWidth, cardHeight, fixedColumnsCount } = this.state
        return (
            <div className={`table-customizer-background ${cursorWait ? 'cursor-wait' : ''}`}>
                <DndProvider backend={HTML5Backend}>
                    <div className="table-customizer-card" ref={this.cardRef}>
                        <DragLayer
                            cardWidth={cardWidth}
                            cardHeight={cardHeight}
                            translate={this.context.t}
                        />

                        <h3>{this.context.t("Column editor")}</h3>

                        <div className="description">
                            {this.context.t("Here you can manage the columns that will be visible in the EasyDocs tables. Drag a column to the \"Selected Columns\" box to add it to the table. You can also remove a column by dragging it to the \"Available Columns\" field or change their order.")}
                        </div>

                        <div className="fixed-columns-number">
                            <div className="icon"></div>
                            <div className="text">
                                <h6>{this.context.t("Lock columns")}</h6>
                                <p>{this.context.t("Keep the locked columns in view as you scroll through the rest of the table")}</p>
                            </div>
                            <div className="number">
                                {fixedColumnsCount}
                                <span className="increment" onClick={() => this.changeFixedColumnsCountCount('PLUS')}></span>
                                <span className="decrement" onClick={() => this.changeFixedColumnsCountCount('MINUS')}></span>
                            </div>
                        </div>

                        <div className="creator-continer">
                            <div className="columns available">
                                <div className="title available">
                                    {this.context.t('Available columns')}
                                </div>
                                <DropTarget
                                    name='available'
                                    containedItems={[...availableColumns]}
                                    handleItemDrop={this.handleDrop}
                                    moveItem={() => { }}
                                    moveNewItem={() => { }}
                                    removeAllPseudoElements={this.removeAllPseudoElements}

                                />
                            </div>

                            <div className="columns selected">
                                <div className="title selected">
                                    {this.context.t('Selected columns')}
                                </div>
                                <DropTarget
                                    name='selected'
                                    containedItems={[...selectedColumns]}
                                    handleItemDrop={this.handleDrop}
                                    moveItem={this.sortSelected}
                                    moveNewItem={this.addAndSort}
                                    removeAllPseudoElements={this.removeAllPseudoElements}
                                />
                            </div>
                        </div>

                        <div className="action-buttons">
                            <div className="button cancel" onClick={() => this.props.close()}>{this.context.t("Cancel")}</div>
                            <div className="button accept" onClick={() => this.save()}>{this.context.t("Save")}</div>
                        </div>
                    </div>
                </DndProvider>
            </div>
        )
    }
}


TableColumnsCustomizer.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
})

const mapDispatchToProps = {
    saveCustomTableColumns: UserActions.saveCustomTableColumns,
    alertWarn: AlertActions.warning
}

export default connect(mapStateToProps, mapDispatchToProps)(TableColumnsCustomizer)